import React, { useEffect, useState } from 'react'
import { VscClose } from "react-icons/vsc";
import { useDispatch } from 'react-redux';
import { setLoggedIn, setLoginToken } from '../../features/authentication/AuthSlice';
import { useNavigate } from 'react-router-dom';
import { login } from '../../features/authentication/AuthActions';



const TimeoutModal = ({setShowTimeout}) => {
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState('');

    const dispatch = useDispatch();

    const navigate = useNavigate()

    
    useEffect(() => {
        // Retrieve data from local storage when component mounts
        const fetchDataFromLocalStorage = () => {
          const data = localStorage.getItem('loginDetails');
          if (data) {
            // If data is found, parse it from JSON string to object
            setValues(JSON.parse(data));
            // console.log(values, 'Login Details');
          }
        };

        fetchDataFromLocalStorage();
      }, []);

      const handleLogin = () => {
        dispatch(login(values, setError, setLoading, navigate))
        setShowTimeout(false)
        // console.log(values);
      }

    const handleLogout = async () => {
        dispatch(setLoginToken(null))
        dispatch(setLoggedIn(false))
        navigate('/')
      }

      const [seconds, setSeconds] = useState(30);

      useEffect(() => {
        const timer = setInterval(() => {
          setSeconds((prevSeconds) => {
            if (prevSeconds === 1) {
              clearInterval(timer);
              handleLogout();
            }
            return prevSeconds - 1;
          });
        }, 1000);

        return () => clearInterval(timer);
      }, []);



  return (
    <div className='absolute top-[40%] items-center justify-center w-[85%] bg-white px-4 py-6 rounded-md shadow-xl md:w-[40%] lg:w-[30%] lg:py-7 xl:w-[25%]'>
                    <p className='text-sm font-semibold text-black w-full'>
                        You will be signed out soon
                    </p>
                    <p className='text-[10px] font-medium text-black w-full mt-2'>
                        To protect your information, you will be signed out in <span className='font-bold'>{seconds} seconds</span>
                    </p>

                    <div className='flex items-center justify-start w-full mt-6 lg:mt-8'>
                         <button onClick={handleLogin}
                         className='flex items-center justify-center w-24 h-8 rounded-md bg-black text-white text-[9px] border-[1px] border-black 
                                             mr-4'>
                               { loading
                                ?<div className="relative flex items-center justify-center w-5 h-5 border-[3px] border-gray-500 border-solid rounded-full">
                                   <div className="absolute w-5 h-5 border-t-[3px] border-white border-solid rounded-full animate-spin"></div>
                                </div>
                                : 'Stay signed in'
                               }
                         </button>
                    </div>
    </div>
  )
}

export default TimeoutModal
