import React from 'react'
import { GoDotFill } from "react-icons/go";
import image from '../../assets/images/aboutImg2.svg'


const HealthSection2 = () => {
    const items = [
        {
            id: 1,
            title: 'Employee Health:',
            desc: 'We prioritize the health and well-being of our employees by providing a safe and healthy work environment. This includes conducting regular health and safety training programs, promoting awareness of health risks, and providing necessary personal protective equipment (PPE).'
        },
        {
            id: 2,
            title: 'Risk Management:',
            desc: 'We identify and assess potential health and safety risks associated with our operations and implement control measures to minimize or eliminate these risks. Risk assessments are conducted regularly to ensure ongoing compliance with health and safety regulations.'
        },
        {
            id: 3,
            title: 'Incident Reporting and Investigation:',
            desc: 'We have established procedures for reporting and investigating incidents, accidents, and near misses. All incidents are thoroughly investigated to determine root causes and implement corrective actions to prevent recurrence.'
        },
        {
            id: 4,
            title: 'Emergency Preparedness:',
            desc: 'We maintain emergency response plans and procedures to effectively respond to emergencies, including fires, spills, and medical emergencies. Regular drills and training exercises are conducted to ensure preparedness and response effectiveness.'
        },
        {
            id: 5,
            title: 'Compliance:',
            desc: 'We comply with all relevant health and safety legislation, regulations, and industry standards. Regular audits and inspections are conducted to ensure compliance and identify areas for improvement.'
        },
    ]

  return (
    <div className='flex flex-col items-start justify-start w-full font-poppins py-14 px-5 md:flex-row-reverse md:justify-between md:items-center md:pt-20 md:px-10 lg:px-14 xl:px-20'>
         <div className='flex flex-col items-start justify-start w-full md:w-full'>
               <p className='text-sm font-semibold text-[#101828] lg:text-base lg:font-bold xl:text-xl xl:w-[80%]'>
                       HEALTH AND SAFETY
               </p>
               <div className='flex flex-wrap items-start justify-start w-full mt-4 md:justify-between lg:mt-3'>
                            {items.map((item) => {
                                return(
                            <div key={item.id} className='flex flex-row items-start justify-start my-3 md:my-4 md:w-[45%] lg:my-5 xl:my-8'>
                                <GoDotFill className='text-sm lg:text-base xl:text-lg text-[#2196F3]' />
                                <p className='flex-1 text-xs font-semibold text-[#101828] ml-2 lg:text-sm lg:ml-5 xl:text-lg xl:ml-6'>
                                     {item.title}
                                     <span className='text-[10px] font-normal ml-1 lg:text-xs xl:text-sm xl:ml-2 xl:font-medium'>{item.desc}</span>
                                </p>
                            </div>
                            )
                            })}
               </div>

         </div>

         {/*<div className='flex flex-row items-center justify-start w-full mt-10 md:w-[50%] md:mt-0 xl:w-[44%]'>
               <img className='w-full h-full'
               src={image} alt='mineral resources visual' />
            </div>*/}

    </div>
  )
}

export default HealthSection2
