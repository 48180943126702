import React, { useEffect, useState } from 'react'
import AllRequests from './AllRequests';
import ActiveRequests from './ActiveRequests';
import FulfilledRequests from './FulfilledRequests';
import OrderModal from './OrderModal';



const SupplyRequests = () => {
  const [selected, setSelected] = useState('all');

  const [openModal, setOpenModal] = useState(null);
  const [modalData, setModalData] = useState(null);

  const handleTab = (item) => {
    setSelected(item)
    // console.log(selected);
  }


  return (
    <div className='flex flex-col items-center w-full'>
    <div className='flex flex-col items-start justify-start w-full py-6 px-6 md:px-8 mt-28 lg:mt-0 lg:pt-[120px] xl:pt-[150px] xl:px-8 xl:py-8'>
          {/* TABS */}
        <div className='flex flex-row items-start justify-between w-full mt-5 md:justify-start md:mt-7'>
             <div onClick={()=>handleTab('all')}
             className='flex flex-col items-start cursor-pointer md:items-center'>
                <p className={`text-[10px] font-normal ${selected === 'all' ? 'text-[#2196F3]' : 'text-[#999898]'} md:text-xs`}>
                      All Supply Request
                </p>
                <div className={`flex w-[75%] border-[1px] ${selected === 'all' ? 'border-[#2196F3] ' : 'border-[#FFFFFF] '} mt-[6px] md:w-full`}></div>
             </div>

             <div onClick={()=>handleTab('active')}
             className='flex flex-col items-start md:ml-9 cursor-pointer md:items-center'>
                <p className={`text-[10px] font-normal ${selected === 'active' ? 'text-[#2196F3]' : 'text-[#999898]'} md:text-xs`}>
                      Active Supply Requests
                </p>
                <div className={`flex w-[75%] border-[1px] ${selected === 'active' ? 'border-[#2196F3] ' : 'border-[#FFFFFF] '} mt-[6px] md:w-full`}></div>
             </div>

             <div onClick={()=>handleTab('fulfilled')}
             className='flex flex-col items-start md:ml-9 cursor-pointer md:items-center'>
                <p className={`text-[10px] font-normal ${selected === 'fulfilled' ? 'text-[#2196F3]' : 'text-[#999898]'} md:text-xs`}>
                      Fulfilled Supply Requests
                </p>
                <div className={`flex w-[75%] border-[1px] ${selected === 'fulfilled' ? 'border-[#2196F3] ' : 'border-[#FFFFFF] '} mt-[6px] md:w-full`}></div>
             </div>

        </div>

        {selected === 'all' ? <AllRequests setOpenModal={setOpenModal} setModalData={setModalData} /> 
                            : selected === 'active' ? <ActiveRequests setOpenModal={setOpenModal} setModalData={setModalData} /> 
                            : selected === 'fulfilled' && <FulfilledRequests setOpenModal={setOpenModal} setModalData={setModalData} /> }

    </div>

    {openModal &&
      <div onClick={()=>setOpenModal(false)}
      className='fixed w-full h-full justify-center items-center bg-[#000000] p-5 lg:w-[80%] opacity-50 xl:w-[80%]'></div>
     }
 
     {openModal &&
      <div className='absolute top-32 flex flex-col items-center justify-start w-[90%] z-20 md:w-[80%] lg:w-[70%] xl:w-[65%]'>
              <OrderModal setOpenModal={setOpenModal} modalData={modalData} />  
      </div>
     }
    </div>
  )
}
export default SupplyRequests
