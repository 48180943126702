import React from 'react'
import Navbar from '../general/Navbar'
import logo from '../../assets/images/obaLogo.png'
import logo2 from '../../assets/images/obaLogo2.png'
import background from '../../assets/images/miners.jpg'



const ChainIntro = () => {
  return (
     <div className='flex flex-col items-center justify-center w-full h-[280px] font-poppins z-20 bg-cover bg-center lg:h-[350px] xl:h-[425px]'  
    style={{ backgroundImage: `url(${background})`}}>
    <div className='absolute top-0 w-full mx-auto max-w-screen-2xl h-[280px] bg-[#000000] lg:h-[350px] opacity-50 xl:h-[425px]'></div>
    <div className='flex flex-row items-center justify-center absolute top-0 w-full pt-3'>
         <Navbar logo={logo} logo2={logo2} text2={'#FFFFFF'} linkTexts={'#FFFFFF'} sustain={'#2196F3'} regBackground={'#2196F3'} reg={'#FFFFFF'} />
    </div>

     <div className='flex items-center justify-start w-full pt-24 pb-12 mt-14 z-30 xl:mt-20 px-5 md:px-10 md:pt-36 md:pb-16 lg:px-14 xl:px-20'>
          <p className='text-lg text-white font-semibold md:text-[25px] md:leading-9 lg:text-[27px] lg:w-[80%] lg:leading-10 xl:text-[37px] xl:leading-[55px]'>
              CHAIN OF CUSTODY AND BUYING PROCEDURES FOR METAL AND MINERAL SOURCING
          </p>
    </div>

    </div>
     
  )
}

export default ChainIntro
