import React from 'react'
import { GoDotFill } from "react-icons/go";
import image from '../../assets/images/aboutImg2.svg'


const SourcingSection2 = () => {
    const items1 = [
        {
            id: 1,
            desc: 'OAT Ltd. complies with all applicable laws, regulations, and international standards related to metal sourcing, including environmental, labor, and human rights regulations'
        },
        {
            id: 2,
            desc: 'We ensure that our sourcing practices adhere to industry-specific guidelines and standards, such as the OECD Due Diligence Guidance for Responsible Supply Chains of Minerals.'
        },
    ]


    const items2 = [
        {
            id: 1,
            desc: 'We conduct business with integrity, honesty, and fairness, and will not engage in bribery, corruption, or other unethical practices.'
        },
        {
            id: 2,
            desc: 'We prioritize suppliers who demonstrate a commitment to ethical business conduct, including respect for human rights, labor rights, and environmental sustainability.'
        },
    ]


    const items3 = [
        {
            id: 1,
            desc: 'OAT is committed to minimizing environmental impact throughout the metal sourcing process, from extraction to production to transportation.'
        },
        {
            id: 2,
            desc: 'We prioritize suppliers who implement sustainable mining and production practices, minimize waste and emissions, and prioritize resource efficiency and conservation.'
        },
    ]

    const items4 = [
        {
            id: 1,
            desc: 'OAT recognizes the importance of social responsibility in the metal supply chain and we prioritize suppliers who prioritize worker safety, fair labor practices, and community development.'
        },
        {
            id: 2,
            desc: 'We support initiatives that promote social welfare, such as providing training and education opportunities for workers and investing in local communities.'
        },
    ]

    const items5 = [
        {
            id: 1,
            desc: 'We promote transparency and traceability throughout the metal supply chain, from mine to market.'
        },
        {
            id: 2,
            desc: 'We prioritize suppliers who provide accurate and reliable information about the origin, production, and transportation of metals, and who maintain comprehensive records of their sourcing activities.'
        },
    ]

    const items6 = [
        {
            id: 1,
            desc: 'OAT is committed to continuously improving our sourcing practices through regular review, assessment, and feedback.'
        },
        {
            id: 2,
            desc: 'We solicit feedback from stakeholders, including suppliers, customers, and industry experts, to identify areas for improvement and innovation in our sourcing operations.'
        },
    ]

    const items7 = [
        {
            id: 1,
            desc: 'We monitor and evaluate our sourcing activities regularly to assess compliance with this policy and identify opportunities for improvement.'
        },
    ]

  return (
    <div className='flex flex-col items-start justify-start w-full font-poppins py-14 px-5 md:items-center md:pt-20 md:px-10 lg:px-14 xl:px-20'>
          {/* ITEM 1 */}
         <div className='flex flex-col items-start justify-start w-full md:w-full'>
               <p className='text-sm font-semibold text-[#101828] lg:text-base lg:font-bold xl:text-xl xl:w-[80%]'>
                  1. Compliance with Laws and Regulations:
               </p>
               <div className='flex flex-wrap items-start justify-start w-full mt-4 md:justify-between md:mt-2 lg:mt-3 xl:mt-6'>
                            {items1.map((item) => {
                                return(
                            <div key={item.id} className='flex flex-row items-start justify-start my-2 md:w-[45%]'>
                                <GoDotFill className='text-xs lg:text-base xl:text-lg text-[#2196F3] md:mt-1' />
                                <p className='flex flex-1 text-[10px] font-medium ml-1 text-[#101828] md:leading-5 lg:text-xs lg:leading-6 xl:text-sm xl:ml-2 xl:leading-7'>
                                     {item.desc}
                                </p>
                            </div>
                            )
                            })}
               </div>

         </div>

         {/* ITEM 2 */}
         <div className='flex flex-col items-start justify-start w-full md:w-full mt-8 xl:mt-10'>
               <p className='text-sm font-semibold text-[#101828] lg:text-base lg:font-bold xl:text-xl xl:w-[80%]'>
                  2. Ethical Sourcing Practices:
               </p>
               <div className='flex flex-wrap items-start justify-start w-full mt-4 md:justify-between md:mt-2 lg:mt-3 xl:mt-6'>
                            {items2.map((item) => {
                                return(
                            <div key={item.id} className='flex flex-row items-start justify-start my-2 md:w-[45%]'>
                                <GoDotFill className='text-xs lg:text-base xl:text-lg text-[#2196F3] md:mt-1' />
                                <p className='flex flex-1 text-[10px] font-medium ml-1 text-[#101828] md:leading-5 lg:text-xs lg:leading-6 xl:text-sm xl:ml-2 xl:leading-7'>
                                     {item.desc}
                                </p>
                            </div>
                            )
                            })}
               </div>

         </div>

         {/* ITEM 3 */}
         <div className='flex flex-col items-start justify-start w-full md:w-full mt-8 xl:mt-10'>
               <p className='text-sm font-semibold text-[#101828] lg:text-base lg:font-bold xl:text-xl xl:w-[80%]'>
                  3. Environmental Responsibility:
               </p>
               <div className='flex flex-wrap items-start justify-start w-full mt-4 md:justify-between md:mt-2 lg:mt-3 xl:mt-6'>
                            {items3.map((item) => {
                                return(
                            <div key={item.id} className='flex flex-row items-start justify-start my-2 md:w-[45%]'>
                                <GoDotFill className='text-xs lg:text-base xl:text-lg text-[#2196F3] md:mt-1' />
                                <p className='flex flex-1 text-[10px] font-medium ml-1 text-[#101828] md:leading-5 lg:text-xs lg:leading-6 xl:text-sm xl:ml-2 xl:leading-7'>
                                     {item.desc}
                                </p>
                            </div>
                            )
                            })}
               </div>

         </div>

         {/* ITEM 4 */}
         <div className='flex flex-col items-start justify-start w-full md:w-full mt-8 xl:mt-10'>
               <p className='text-sm font-semibold text-[#101828] lg:text-base lg:font-bold xl:text-xl xl:w-[80%]'>
                    4. Social Responsibility:
               </p>
               <div className='flex flex-wrap items-start justify-start w-full mt-4 md:justify-between md:mt-2 lg:mt-3 xl:mt-6'>
                            {items4.map((item) => {
                                return(
                            <div key={item.id} className='flex flex-row items-start justify-start my-2 md:w-[45%]'>
                                <GoDotFill className='text-xs lg:text-base xl:text-lg text-[#2196F3] md:mt-1' />
                                <p className='flex flex-1 text-[10px] font-medium ml-1 text-[#101828] md:leading-5 lg:text-xs lg:leading-6 xl:text-sm xl:ml-2 xl:leading-7'>
                                     {item.desc}
                                </p>
                            </div>
                            )
                            })}
               </div>

         </div>

         {/* ITEM 5 */}
         <div className='flex flex-col items-start justify-start w-full md:w-full mt-8 xl:mt-10'>
               <p className='text-sm font-semibold text-[#101828] lg:text-base lg:font-bold xl:text-xl xl:w-[80%]'>
                     5. Supply Chain Transparency:
               </p>
               <div className='flex flex-wrap items-start justify-start w-full mt-4 md:justify-between md:mt-2 lg:mt-3 xl:mt-6'>
                            {items5.map((item) => {
                                return(
                            <div key={item.id} className='flex flex-row items-start justify-start my-2 md:w-[45%]'>
                                <GoDotFill className='text-xs lg:text-base xl:text-lg text-[#2196F3] md:mt-1' />
                                <p className='flex flex-1 text-[10px] font-medium ml-1 text-[#101828] md:leading-5 lg:text-xs lg:leading-6 xl:text-sm xl:ml-2 xl:leading-7'>
                                     {item.desc}
                                </p>
                            </div>
                            )
                            })}
               </div>

         </div>

         {/* ITEM 6 */}
         <div className='flex flex-col items-start justify-start w-full md:w-full mt-8 xl:mt-10'>
               <p className='text-sm font-semibold text-[#101828] lg:text-base lg:font-bold xl:text-xl xl:w-[80%]'>
                   6. Continuous Improvement:
               </p>
               <div className='flex flex-wrap items-start justify-start w-full mt-4 md:justify-between md:mt-2 lg:mt-3 xl:mt-6'>
                            {items6.map((item) => {
                                return(
                            <div key={item.id} className='flex flex-row items-start justify-start my-2 md:w-[45%]'>
                                <GoDotFill className='text-xs lg:text-base xl:text-lg text-[#2196F3] md:mt-1' />
                                <p className='flex flex-1 text-[10px] font-medium ml-1 text-[#101828] md:leading-5 lg:text-xs lg:leading-6 xl:text-sm xl:ml-2 xl:leading-7'>
                                     {item.desc}
                                </p>
                            </div>
                            )
                            })}
               </div>

         </div>

         {/* ITEM 7 */}
         <div className='flex flex-col items-start justify-start w-full md:w-full mt-8 xl:mt-10'>
               <p className='text-sm font-semibold text-[#101828] lg:text-base lg:font-bold xl:text-xl xl:w-[80%]'>
                      7. Implementation and Monitoring:
               </p>
               <div className='flex flex-wrap items-start justify-start w-full mt-4 md:justify-between md:mt-2 lg:mt-3 xl:mt-6'>
                            {items7.map((item) => {
                                return(
                            <div key={item.id} className='flex flex-row items-start justify-start my-2 md:w-[45%]'>
                                <GoDotFill className='text-xs lg:text-base xl:text-lg text-[#2196F3] md:mt-1' />
                                <p className='flex flex-1 text-[10px] font-medium ml-1 text-[#101828] md:leading-5 lg:text-xs lg:leading-6 xl:text-sm xl:ml-2 xl:leading-7'>
                                     {item.desc}
                                </p>
                            </div>
                            )
                            })}
               </div>

         </div>

         {/* CONCLUSION */}
         <div className='flex flex-col items-start justify-start w-full md:w-full mt-8 xl:mt-10'>
               <div className='flex flex-wrap items-start justify-start w-full mt-4 md:justify-between md:mt-2 lg:mt-3 xl:mt-6'>
                            <div className='flex flex-row items-start justify-start my-2 lg:w-[85%] xl:w-[90%]'>
                                <p className='flex-1 text-xs font-semibold text-[#101828] ml-2 lg:text-sm lg:ml-5 xl:text-xl xl:ml-6'>
                                     Conclusion:
                                    <span className='text-[10px] font-normal ml-1 lg:text-xs xl:text-base xl:ml-2 xl:font-medium'>
                                          OAT is dedicated to responsible sourcing practices that prioritize ethical, legal, and
                                          sustainable procurement of metals. By adhering to this policy and working
                                          collaboratively with our suppliers and stakeholders, we aim to promote transparency,
                                          integrity, and social responsibility in the metal supply chain.
                                    </span>
                                </p>
                            </div>
               </div>

         </div>
        

         {/*<div className='flex flex-row items-center justify-start w-full mt-10 md:w-[50%] md:mt-0 xl:w-[44%]'>
               <img className='w-full h-full'
               src={image} alt='mineral resources visual' />
            </div>*/}

    </div>
  )
}

export default SourcingSection2
