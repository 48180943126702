import React, { useEffect, useState } from 'react'
import { GoDotFill } from "react-icons/go";
import { GoCheck } from "react-icons/go";
import { useDispatch } from 'react-redux';
import { sellerNotifications, sellersUnreadNot, sellersmarkUnreadNot } from '../../../features/seller/SellerAction';



const SellerNotification = () => {
    const [loading, setLoading] = useState(false)
    const [loadUnread, setLoadUnread] = useState(false)
    const [data, setData] = useState([])
    const [unread, setUnread] = useState([])


    const dispatch = useDispatch();


     useEffect(() => {
          dispatch(sellerNotifications(setData, setLoading))
          dispatch(sellersUnreadNot(setUnread, setLoadUnread))
     }, [dispatch])


     const handleUnread = (item) => {
        dispatch(sellersmarkUnreadNot(item))
        // console.log(item, 'deibieb')
     }


  return (
    <div className='flex flex-col items-center justify-start w-full mt-28 lg:mt-0 lg:pt-[120px] xl:pt-[150px] xl:py-8'>
        <div className='flex items-start justify-start w-full pt-6 px-5 md:px-10 xl:px-8'>
            <p className='text-sm font-medium text-[#101828] lg:text-base xl:text-xl'>
                You have {unread.unread_notification_count} unread notifications
            </p>
        </div>


        <div className='flex flex-col items-center justify-start w-full mt-7 pb-10 lg:mt-9'>

        {data.map((item) => {
            return (
        <div key={item.id} onClick={()=>handleUnread(item.id)}
        className={`flex flex-col items-center justify-start w-full pt-5 px-5 ${item.is_read === false && 'bg-[#f7f7f7]'} md:px-10 lg:items-start xl:px-8`}>
          <div className='flex items-start justify-start w-full lg:items-center lg:w-[90%] xl:w-[80%]'>
          
               <div className='flex items-center justify-start'>
                     {item.is_read === false &&
                     <GoDotFill className={`text-[10px] text-[#DA1E28] xl:text-xs`} />
                     }
                     <div className='flex items-center justify-center h-7 w-7 bg-[#DCF0E2] ml-2 rounded-full lg:ml-4 xl:h-9 xl:w-9'>
                         <GoCheck className='text-sm text-[#25A249] xl:text-base' />
                     </div>
               </div>


               <div className='flex flex-col items-start justify-start ml-5'>
                    <p className='text-[11px] font-semibold text-[#101828] xl:text-[13px]'>
                          {item.title}
                    </p>
                    <p className='text-[10px] font-normal text-[#667085] mt-1 xl:text-xs xl:leading-5'>
                          {item.body} 
                    </p>
                    <p className='text-[10px] font-semibold text-[#667085] mt-2 xl:text-xs xl:mt-3'>
                          {item.created_at.slice(11, 16)}
                    </p>
               </div>
          </div>

          <div className='border-[0.5px] border-[#DDE1E6] w-full mt-3 opacity-30'></div>

        </div>
            )
        })}
        
        </div>

    </div>
  )
}

export default SellerNotification
