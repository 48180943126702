import React from 'react'
import Navbar from '../general/Navbar'
import logo from '../../assets/images/obaLogo.png'
import logo2 from '../../assets/images/obaLogo2.png'
import background from '../../assets/images/safety.jpg'



const SustainIntro = () => {
  return (
     <div className='flex flex-col items-center justify-center w-full h-[380px] font-poppins z-20 bg-cover bg-center lg:h-[400px] xl:h-[470px]'  
    style={{ backgroundImage: `url(${background})`}}>
    <div className='absolute top-0 w-full mx-auto max-w-screen-2xl h-[380px] bg-[#000000] lg:h-[400px] opacity-50 xl:h-[470px]'></div>
    <div className='flex flex-row items-center justify-center absolute top-0 w-full pt-3'>
         <Navbar logo={logo} logo2={logo2} text2={'#FFFFFF'} linkTexts={'#FFFFFF'} sustain={'#2196F3'} regBackground={'#2196F3'} reg={'#FFFFFF'} />
    </div>

     <div className='flex flex-col items-start justify-center w-full pt-32 pb-12 mt-14 z-30 xl:mt-20 px-5 md:px-10 md:pt-36 md:pb-16 lg:px-14 xl:px-20'>
          <p className='text-lg text-white font-semibold md:text-[25px] lg:text-[27px] xl:text-[37px]'>
                Health, Safety, and Environment (HSE) Policy
          </p>
          <p className='text-[10px] text-white font-normal leading-6 mt-2 md:mt-3 md:text-[12px] lg:text-sm lg:leading-8 lg:w-[85%] xl:w-[80%] xl:mt-7 xl:text-[15px] xl:leading-10 xl:text-base'>
                At OAT, we are committed to ensuring the health, safety, and well-being of all
                employees, contractors, visitors, and the environment in which we operate. Our HSE
                policy outlines our commitment to maintaining high standards of health, safety, and
                environmental management in all aspects of our operations.
          </p>
    </div>

    </div>
     
  )
}

export default SustainIntro
