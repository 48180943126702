import React from 'react'
import { GoDotFill } from "react-icons/go";
import image from '../../assets/images/aboutImg2.svg'


const HealthSection3 = () => {
    const items = [
        {
            id: 1,
            title: 'Environmental Protection:',
            desc: 'We are committed to minimizing our environmental footprint and protecting natural resources. We implement practices to reduce waste generation, conserve energy and water, and prevent pollution.'
        },
        {
            id: 2,
            title: 'Sustainable Practices:',
            desc: 'We promote sustainability in our operations by sourcing materials responsibly, supporting recycling initiatives, and seeking opportunities to reduce environmental impact throughout our supply chain.'
        },
        {
            id: 3,
            title: 'Biodiversity Conservation:',
            desc: 'We recognize the importance of biodiversity conservation and strive to minimize our impact on ecosystems and wildlife habitats in areas where we operate.'
        },
        {
            id: 4,
            title: 'Community Engagement:',
            desc: 'We engage with local communities to understand their environmental concerns and mitigate any potential adverse impacts of our operations. We are committed to being good stewards of the environment and contributing positively to the communities in which we operate.'
        },
    ]

  return (
    <div className='flex flex-col items-start justify-start w-full font-poppins pb-12 px-5 md:flex-row-reverse md:justify-between md:items-start md:px-10 lg:px-14 xl:px-20'>
         <div className='flex flex-col items-start justify-start w-full'>
               <p className='text-sm font-semibold text-[#101828] lg:text-base lg:font-bold xl:text-xl xl:w-[80%]'>
                    ENVIRONMENT
               </p>
               <div className='flex flex-wrap items-start justify-start w-full mt-4 md:justify-between lg:mt-3'>
                            {items.map((item) => {
                                return(
                            <div key={item.id} className='flex flex-row items-start justify-start my-3 md:my-4 md:w-[45%] lg:my-5 xl:my-8'>
                                <GoDotFill className='text-sm text-[#2196F3]' />
                                <p className='flex-1 text-xs font-semibold text-[#101828] ml-2 lg:text-sm lg:ml-5 xl:text-lg xl:ml-6'>
                                     {item.title}
                                     <span className='text-[10px] font-normal ml-1 lg:text-xs xl:text-sm xl:ml-2 xl:font-medium'>{item.desc}</span>
                                </p>
                            </div>
                            )
                            })}
               </div>

         </div>

         {/*<div className='flex flex-row items-center justify-start w-full mt-10 md:hidden lg:flex lg:w-[45%] md:mt-0 xl:w-[44%]'>
               <img className='w-full h-full'
               src={image} alt='mineral resources visual' />
           </div>*/}

    </div>
  )
}

export default HealthSection3
