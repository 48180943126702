import React, { useEffect } from 'react'
import Footer from '../components/general/Footer'
import { useLocation } from 'react-router-dom';
import ChainIntro from '../components/chain/ChainIntro';
import ChainSection2 from '../components/chain/ChainSection2';
import MetaTags from '../MetaTags';


const Chain = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  return (
    <div className='flex flex-col items-center justify-start mx-auto max-w-screen-2xl'>
        <MetaTags
         title="Chain of custody" 
         description="Find out about our chain of custody and buying procedures" 
         link="/chain"
          />
         <ChainIntro />
         <ChainSection2 />
         <Footer />
    </div>
  )
}

export default Chain
