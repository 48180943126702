import React from 'react'
import { GoDotFill } from "react-icons/go";
import image from '../../assets/images/aboutImg2.svg'


const HealthSection4 = () => {
    const items = [
        {
            id: 1,
            title: 'Performance Monitoring:',
            desc: 'We regularly monitor and measure our HSE performance against established targets and objectives. Performance indicators are tracked to identify trends, evaluate effectiveness, and drive continuous improvement.'
        },
        {
            id: 2,
            title: 'Training and Awareness:',
            desc: 'We provide ongoing training and awareness programs to employees, contractors, and stakeholders to promote a culture of safety, environmental stewardship, and continuous improvement.'
        },
        {
            id: 2,
            title: 'Stakeholder Engagement:',
            desc: 'We actively engage with stakeholders, including employees, contractors, regulators, and local communities, to solicit feedback, address concerns, and foster collaboration in achieving our HSE goals.'
        },
    ]

  return (
    <div className='flex flex-col items-start justify-start w-full font-poppins pb-14 px-5 md:flex-row-reverse md:justify-between md:items-start md:px-10 lg:px-14 xl:px-20'>
         <div className='flex flex-col items-start justify-start w-full'>
               <p className='text-sm font-semibold text-[#101828] lg:text-base lg:font-bold xl:text-xl xl:w-[80%]'>
                    Continuous Improvement
               </p>
               <div className='flex flex-wrap items-start justify-start w-full mt-4 md:justify-between lg:mt-3'>
                            {items.map((item) => {
                                return(
                            <div key={item.id} className='flex flex-row items-start justify-start my-3 md:my-4 md:w-[45%] lg:my-5 xl:my-8'>
                                <GoDotFill className='text-sm text-[#2196F3]' />
                                <p className='flex-1 text-xs font-semibold text-[#101828] ml-2 lg:text-sm lg:ml-5 xl:text-lg xl:ml-6'>
                                     {item.title}
                                     <span className='text-[10px] font-normal ml-1 lg:text-xs xl:text-sm xl:ml-2 xl:font-medium'>{item.desc}</span>
                                </p>
                            </div>
                            )
                            })}
               </div>

         </div>

         {/*<div className='flex flex-row items-center justify-start w-full mt-10 md:hidden lg:flex lg:w-[45%] md:mt-0 xl:w-[44%]'>
               <img className='w-full h-full'
               src={image} alt='mineral resources visual' />
           </div>*/}

    </div>
  )
}

export default HealthSection4
