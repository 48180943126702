import React from 'react'
import { GoDotFill } from "react-icons/go";
import image from '../../assets/images/aboutImg2.svg'


const ChainSection2 = () => {
    const top = [
        {
            id: 1,
            desc: 'Provide organizations in the chain of custody with a common standard for handling and making claims regarding OAT-conformant responsibly mined materials in their possession and their products.'
        },
        {
            id: 2,
            desc: 'Establish requirements that can be independently audited to provide objective evidence for the flow of OAT- conformant responsibly mined materials.'
        },
    ]


    const items1 = [
        {
            id: 1,
            desc: 'Conduct a thorough assessment of potential suppliers and sources to ensure compliance with legal and ethical standards.'
        },
        {
            id: 2,
            desc: 'Verify the authenticity and legality of the metal and mineral sources through documentation and site visits if necessary.'
        },
    ]


    const items2 = [
        {
            id: 1,
            desc: 'We prioritize suppliers who adhere to environmental, social, and governance (ESG) principles and have transparent supply chains'
        },
        {
            id: 2,
            desc: 'Prioritize suppliers with certifications such as Responsible Mineral Initiative (RMI) or Conflict-Free Sourcing Initiative (CFSI).'
        },
    ]


    const items3 = [
        {
            id: 1,
            desc: 'Negotiate contracts that outline terms and conditions for sourcing, including quality standards, pricing, delivery schedules, and compliance requirement.'
        },
        {
            id: 2,
            desc: 'Include clauses for traceability, transparency, and compliance with local and international regulations.'
        },
    ]

    const items4 = [
        {
            id: 1,
            desc: 'We implement a robust system for tracking the origin and movement of metals and minerals throughout the supply chain.'
        },
    ]

    const items5 = [
        {
            id: 1,
            desc: 'We conduct regular due diligence assessments to monitor supplier compliance with contractual obligations and regulatory requirements.'
        },
        {
            id: 2,
            desc: 'Stay informed about changes in laws and regulations related to metal and mineral sourcing, and ensure compliance at all times.'
        },
    ]

    const items6 = [
        {
            id: 1,
            desc: 'Establish quality control measures to ensure that sourced metals and minerals meet specified standards and specifications.'
        },
        {
            id: 2,
            desc: 'Conduct regular inspections and testing of incoming materials to verify quality and integrity'
        },
        {
            id: 3,
            desc: 'We contract an internationally recognized third party such as SGS, BV, etc. to serve as external quality control agents'
        },
    ]

    const items7 = [
        {
            id: 1,
            desc: 'Promote ethical and sustainable sourcing practices by engaging with suppliers who prioritize environmental protection, worker safety, and community development.'
        },
        {
            id: 2,
            desc: 'Encourage suppliers to adopt responsible mining practices and support initiatives for social and environmental responsibility'
        },
    ]

    const items8 = [
        {
            id: 1,
            desc: 'Continuously review and improve sourcing practices to enhance efficiency, transparency, and sustainability.'
        },
        {
            id: 2,
            desc: 'Solicit feedback from stakeholders, including suppliers, customers, and industry experts, to identify areas for improvement and innovation.'
        },
    ]

  return (
    <div className='flex flex-col items-start justify-start w-full font-poppins pt-5 px-5 md:items-center md:pt-8 md:px-10 lg:pt-14 lg:px-14 xl:px- xl:pt-16'>
          {/* TOP */}
         <div className='flex flex-col items-start justify-start w-full md:w-full mt-8 xl:mt-10'>
              <p className='text-xs font-semibold text-[#101828] lg:text-sm xl:text-xl xl:w-[80%]'>
                   At OAT, our Chain of Custody and buying procedure standards aims to:
              </p>
              <div className='flex flex-wrap items-start justify-start w-full mt-4 md:justify-between md:mt-2 lg:mt-3 xl:mt-6'>
                           {top.map((item) => {
                               return(
                           <div key={item.id} className='flex flex-row items-start justify-start my-2 md:w-[45%]'>
                               <GoDotFill className='text-xs lg:text-base xl:text-lg text-[#2196F3] md:mt-1' />
                               <p className='flex flex-1 text-[10px] font-medium ml-1 text-[#101828] md:leading-5 lg:text-xs lg:leading-6 xl:text-sm xl:ml-2 xl:leading-7'>
                                    {item.desc}
                               </p>
                           </div>
                           )
                           })}
              </div>

         </div>

          <p className='w-full text-start text-[15px] font-bold text-[#2196F3] my-5 md:my-10 md:text-lg lg:text-xl lg:my-14 lg:font-bold xl:text-3xl xl:my-16'>
                OUR CHAIN OF CUSTODY PROCEDURE
          </p>

          {/* ITEM 1 */}
         <div className='flex flex-col items-start justify-start w-full md:w-full'>
               <p className='text-sm font-semibold text-[#101828] lg:text-base lg:font-bold xl:text-xl xl:w-[80%]'>
                  1. Initial Assessment:
               </p>
               <div className='flex flex-wrap items-start justify-start w-full mt-4 md:justify-between md:mt-2 lg:mt-3 xl:mt-6'>
                            {items1.map((item) => {
                                return(
                            <div key={item.id} className='flex flex-row items-start justify-start my-2 md:w-[45%]'>
                                <GoDotFill className='text-xs lg:text-base xl:text-lg text-[#2196F3] md:mt-1' />
                                <p className='flex flex-1 text-[10px] font-medium ml-1 text-[#101828] md:leading-5 lg:text-xs lg:leading-6 xl:text-sm xl:ml-2 xl:leading-7'>
                                     {item.desc}
                                </p>
                            </div>
                            )
                            })}
               </div>

         </div>

         {/* ITEM 2 */}
         <div className='flex flex-col items-start justify-start w-full md:w-full mt-8 xl:mt-10'>
               <p className='text-sm font-semibold text-[#101828] lg:text-base lg:font-bold xl:text-xl xl:w-[80%]'>
                    2. Supplier Selection:
               </p>
               <div className='flex flex-wrap items-start justify-start w-full mt-4 md:justify-between md:mt-2 lg:mt-3 xl:mt-6'>
                            {items2.map((item) => {
                                return(
                            <div key={item.id} className='flex flex-row items-start justify-start my-2 md:w-[45%]'>
                                <GoDotFill className='text-xs lg:text-base xl:text-lg text-[#2196F3] md:mt-1' />
                                <p className='flex flex-1 text-[10px] font-medium ml-1 text-[#101828] md:leading-5 lg:text-xs lg:leading-6 xl:text-sm xl:ml-2 xl:leading-7'>
                                     {item.desc}
                                </p>
                            </div>
                            )
                            })}
               </div>

         </div>

         {/* ITEM 3 */}
         <div className='flex flex-col items-start justify-start w-full md:w-full mt-8 xl:mt-10'>
               <p className='text-sm font-semibold text-[#101828] lg:text-base lg:font-bold xl:text-xl xl:w-[80%]'>
                     3. Contract Negotiation:
               </p>
               <div className='flex flex-wrap items-start justify-start w-full mt-4 md:justify-between md:mt-2 lg:mt-3 xl:mt-6'>
                            {items3.map((item) => {
                                return(
                            <div key={item.id} className='flex flex-row items-start justify-start my-2 md:w-[45%]'>
                                <GoDotFill className='text-xs lg:text-base xl:text-lg text-[#2196F3] md:mt-1' />
                                <p className='flex flex-1 text-[10px] font-medium ml-1 text-[#101828] md:leading-5 lg:text-xs lg:leading-6 xl:text-sm xl:ml-2 xl:leading-7'>
                                     {item.desc}
                                </p>
                            </div>
                            )
                            })}
               </div>

         </div>

         {/* ITEM 4 */}
         <div className='flex flex-col items-start justify-start w-full md:w-full mt-8 xl:mt-10'>
               <p className='text-sm font-semibold text-[#101828] lg:text-base lg:font-bold xl:text-xl xl:w-[80%]'>
                    4. Traceability and Documentation:
               </p>
               <div className='flex flex-wrap items-start justify-start w-full mt-4 md:justify-between md:mt-2 lg:mt-3 xl:mt-6'>
                            {items4.map((item) => {
                                return(
                            <div key={item.id} className='flex flex-row items-start justify-start my-2 md:w-[45%]'>
                                <GoDotFill className='text-xs lg:text-base xl:text-lg text-[#2196F3] md:mt-1' />
                                <p className='flex flex-1 text-[10px] font-medium ml-1 text-[#101828] md:leading-5 lg:text-xs lg:leading-6 xl:text-sm xl:ml-2 xl:leading-7'>
                                     {item.desc}
                                </p>
                            </div>
                            )
                            })}
               </div>

         </div>

         {/* ITEM 5 */}
         <div className='flex flex-col items-start justify-start w-full md:w-full mt-8 xl:mt-10'>
               <p className='text-sm font-semibold text-[#101828] lg:text-base lg:font-bold xl:text-xl xl:w-[80%]'>
                     5. Due Diligence and Compliance:
               </p>
               <div className='flex flex-wrap items-start justify-start w-full mt-4 md:justify-between md:mt-2 lg:mt-3 xl:mt-6'>
                            {items5.map((item) => {
                                return(
                            <div key={item.id} className='flex flex-row items-start justify-start my-2 md:w-[45%]'>
                                <GoDotFill className='text-xs lg:text-base xl:text-lg text-[#2196F3] md:mt-1' />
                                <p className='flex flex-1 text-[10px] font-medium ml-1 text-[#101828] md:leading-5 lg:text-xs lg:leading-6 xl:text-sm xl:ml-2 xl:leading-7'>
                                     {item.desc}
                                </p>
                            </div>
                            )
                            })}
               </div>

         </div>

         {/* ITEM 6 */}
         <div className='flex flex-col items-start justify-start w-full md:w-full mt-8 xl:mt-10'>
               <p className='text-sm font-semibold text-[#101828] lg:text-base lg:font-bold xl:text-xl xl:w-[80%]'>
                     6. Quality Assurance:
               </p>
               <div className='flex flex-wrap items-start justify-start w-full mt-4 md:justify-between md:mt-2 lg:mt-3 xl:mt-6'>
                            {items6.map((item) => {
                                return(
                            <div key={item.id} className='flex flex-row items-start justify-start my-2 md:w-[45%]'>
                                <GoDotFill className='text-xs lg:text-base xl:text-lg text-[#2196F3] md:mt-1' />
                                <p className='flex flex-1 text-[10px] font-medium ml-1 text-[#101828] md:leading-5 lg:text-xs lg:leading-6 xl:text-sm xl:ml-2 xl:leading-7'>
                                     {item.desc}
                                </p>
                            </div>
                            )
                            })}
               </div>

         </div>

         {/* ITEM 7 */}
         <div className='flex flex-col items-start justify-start w-full md:w-full mt-8 xl:mt-10'>
               <p className='text-sm font-semibold text-[#101828] lg:text-base lg:font-bold xl:text-xl xl:w-[80%]'>
                     7. Ethical and Sustainable Sourcing:
               </p>
               <div className='flex flex-wrap items-start justify-start w-full mt-4 md:justify-between md:mt-2 lg:mt-3 xl:mt-6'>
                            {items7.map((item) => {
                                return(
                            <div key={item.id} className='flex flex-row items-start justify-start my-2 md:w-[45%]'>
                                <GoDotFill className='text-xs lg:text-base xl:text-lg text-[#2196F3] md:mt-1' />
                                <p className='flex flex-1 text-[10px] font-medium ml-1 text-[#101828] md:leading-5 lg:text-xs lg:leading-6 xl:text-sm xl:ml-2 xl:leading-7'>
                                     {item.desc}
                                </p>
                            </div>
                            )
                            })}
               </div>

         </div>

         {/* ITEM 8 */}
         <div className='flex flex-col items-start justify-start w-full md:w-full mt-8 xl:mt-10'>
               <p className='text-sm font-semibold text-[#101828] lg:text-base lg:font-bold xl:text-xl xl:w-[80%]'>
                      8. Continuous Improvement:
               </p>
               <div className='flex flex-wrap items-start justify-start w-full mt-4 md:justify-between md:mt-2 lg:mt-3 xl:mt-6'>
                            {items8.map((item) => {
                                return(
                            <div key={item.id} className='flex flex-row items-start justify-start my-2 md:w-[45%]'>
                                <GoDotFill className='text-xs lg:text-base xl:text-lg text-[#2196F3] md:mt-1' />
                                <p className='flex flex-1 text-[10px] font-medium ml-1 text-[#101828] md:leading-5 lg:text-xs lg:leading-6 xl:text-sm xl:ml-2 xl:leading-7'>
                                     {item.desc}
                                </p>
                            </div>
                            )
                            })}
               </div>

         </div>

         {/* CONCLUSION */}
         <div className='flex flex-col items-start justify-start w-full mt-5 mb-10 md:w-full md:mt-8 xl:my-14'>
               <div className='flex flex-wrap items-start justify-start w-full mt-4 md:justify-between md:mt-2 lg:mt-3 xl:mt-6'>
                            <div className='flex flex-row items-start justify-start my-2 lg:w-[85%] xl:w-[90%]'>
                                <p className='flex-1 text-xs font-semibold text-[#101828] ml-2 lg:text-sm lg:ml-5 xl:text-xl xl:ml-6'>
                                     Conclusion:
                                    <span className='text-[10px] font-normal ml-1 lg:text-xs xl:text-base xl:ml-2 xl:font-medium'>
                                          By implementing robust chain of custody and buying procedures, we aim to ensure the
                                          ethical, legal, and sustainable sourcing of metals and minerals. Through collaboration
                                          with responsible suppliers and adherence to best practices, we at OAT Ltd. are
                                          committed to promoting transparency, accountability, and positive social and
                                          environmental impacts in the metal and mineral supply chain.
                                    </span>
                                </p>
                            </div>
               </div>

         </div>
        

         {/*<div className='flex flex-row items-center justify-start w-full mt-10 md:w-[50%] md:mt-0 xl:w-[44%]'>
               <img className='w-full h-full'
               src={image} alt='mineral resources visual' />
            </div>*/}

    </div>
  )
}

export default ChainSection2
