import React from 'react'
import Navbar from '../general/Navbar'
import logo from '../../assets/images/obaLogo.png'
import logo2 from '../../assets/images/obaLogo2.png'
import background from '../../assets/images/privacy.jpg'



const SourcingIntro = () => {
  return (
     <div className='flex flex-col items-center justify-center w-full h-[380px] font-poppins z-20 bg-cover bg-center lg:h-[400px] xl:h-[470px]'  
    style={{ backgroundImage: `url(${background})`}}>
    <div className='absolute top-0 w-full mx-auto max-w-screen-2xl h-[380px] bg-[#000000] lg:h-[400px] opacity-50 xl:h-[470px]'></div>
    <div className='flex flex-row items-center justify-center absolute top-0 w-full pt-3'>
         <Navbar logo={logo} logo2={logo2} text2={'#FFFFFF'} linkTexts={'#FFFFFF'} sustain={'#2196F3'} regBackground={'#2196F3'} reg={'#FFFFFF'} />
    </div>

     <div className='flex flex-col items-start justify-center w-full pt-24 pb-12 mt-14 z-30 xl:mt-20 px-5 md:px-10 md:pt-36 md:pb-16 lg:px-14 xl:px-20'>
          <p className='text-lg text-white font-semibold md:text-[25px] lg:text-[27px] xl:text-[37px]'>
                RESPONSIBLE SOURCING POLICY AND PROCEDURES
          </p>
          <p className='text-[10px] text-white font-normal leading-6 mt-2 md:mt-3 md:text-[12px] lg:leading-8 lg:w-[85%] xl:w-[80%] xl:mt-7 xl:text-[15px] xl:leading-10'>
               At OAT, we are committed to responsible sourcing practices that prioritize ethical,
               legal, and sustainable procurement of metals. Our policy outlines our commitment to
               transparency, integrity, and social responsibility in all aspects of our sourcing
               operations. <br />
               Our Responsible Sourcing Policy & Procedures includes
          </p>
    </div>

    </div>
     
  )
}

export default SourcingIntro
