import React from 'react'
import { VscClose } from "react-icons/vsc";


const RolesModal = () => {
  return (
    <div className='absolute flex flex-col items-center justify-start w-[90%] mt-28 bg-white rounded-lg px-4 py-5 md:w-[85%] lg:w-[70%] lg:px-6 xl:mt-36 xl:w-[65%] xl:px-9 xl:py-7'>
        <div className='flex items-center justify-between w-full'>
            <p className='text-xs font-medium text-black xl:text-base'>
                 New user Role
            </p>
            <VscClose className='text-lg xl:text-2xl' />
        </div>


        <div className='flex flex-col items-start justify-start w-full mt-7'>
             <p className='text-[9px] font-medium text-black xl:text-xs'>
                 Role Name
             </p>
             <input className='h-8 border-[#dddddd] border-[1px] rounded-md w-full mt-1 xl:h-10'
              />
        </div>


        <p className='text-[9px] font-medium text-[#667085] w-full text-start mt-7 xl:text-xs xl:mt-10'>
              PERMISSIONS
        </p>


        <div className="overflow-x-auto w-full no-scrollbar mt-3 md:mt-5">
            <table className="table-auto border-collapse w-full">
              <thead className=''>
                <tr className='w-full'>
                  <th className="px-2 py-3 text-[10px] border-b-[1px] border-[#E1DEE0] font-medium text-start text-[#667085] md:py-3 lg:py-3 xl:px-4 xl:py-4 xl:text-sm"></th>

                  <th className="py-3 text-[10px] border-b-[1px] border-[#E1DEE0] font-medium text-start text-[#667085] md:py-3 lg:py-3 xl:px-8 xl:py-4 xl:text-sm">
                    <p className='w-[120px]'>
                          Permission
                    </p>
                  </th>

                  <th className="px-4 py-3 text-[10px] border-b-[1px] border-[#E1DEE0] font-medium text-start text-[#667085] md:py-3 lg:py-3 xl:px-8 xl:py-4 xl:text-sm">
                       All 
                  </th>

                  <th className="px-4 py-3 text-[10px] border-b-[1px] border-[#E1DEE0] font-medium text-start text-[#667085] md:py-3 lg:py-3 xl:px-8 xl:py-4 xl:text-sm">
                       Create 
                  </th>

                  <th className="px-4 py-3 text-[10px] border-b-[1px] border-[#E1DEE0] font-medium text-start text-[#667085] md:py-3 lg:py-3 xl:px-8 xl:py-4 xl:text-sm">
                       Update 
                  </th>

                  <th className="px-4 py-3 text-[10px] border-b-[1px] border-[#E1DEE0] font-medium text-start text-[#667085] md:py-3 lg:py-3 xl:px-8 xl:py-4 xl:text-sm">
                       Delete 
                  </th>

                  <th className="px-4 py-3 text-[10px] border-b-[1px] border-[#E1DEE0] font-medium text-start text-[#667085] md:py-3 lg:py-3 xl:px-8 xl:py-4 xl:text-sm">
                       Read Only 
                  </th>

                </tr>
              </thead>

              <tbody>
                <tr className='w-full bg-white'>
                  <td className="px-2 pr-8 py-4 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-4 xl:py-6 xl:text-sm">1</td>

                  <td className="py-6 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-8 xl:py-6 xl:text-sm">Manage Users</td>

                  <td className="px-4 py-3 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-8 xl:py-6 xl:text-sm">
                     <div className='flex flex-wrap items-center justify-start w-4 h-4 border-[1px] border-[#E1DEE0] rounded-sm xl:w-5 xl:h-5'>
                     </div>
                  </td>

                  <td className="px-4 py-3 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-8 xl:py-6 xl:text-sm">
                     <div className='flex flex-wrap items-center justify-start w-4 h-4 border-[1px] border-[#E1DEE0] rounded-sm xl:w-5 xl:h-5'>
                     </div>
                  </td>

                  <td className="px-4 py-3 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-8 xl:py-6 xl:text-sm">
                     <div className='flex flex-wrap items-center justify-start w-4 h-4 border-[1px] border-[#E1DEE0] rounded-sm xl:w-5 xl:h-5'>
                     </div>
                  </td>

                  <td className="px-4 py-3 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-8 xl:py-6 xl:text-sm">
                     <div className='flex flex-wrap items-center justify-start w-4 h-4 border-[1px] border-[#E1DEE0] rounded-sm xl:w-5 xl:h-5'>
                     </div>
                  </td>
                  
                  <td className="px-4 py-3 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-8 xl:py-6 xl:text-sm">
                     <div className='flex flex-wrap items-center justify-start w-4 h-4 border-[1px] border-[#E1DEE0] rounded-sm xl:w-5 xl:h-5'>
                     </div>
                  </td>
                </tr>


                <tr className='w-full bg-white'>
                  <td className="px-2 pr-8 py-4 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-4 xl:py-6 xl:text-sm">2</td>

                  <td className="py-6 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-8 xl:py-6 xl:text-sm">Manage Commodities</td>

                  <td className="px-4 py-3 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-8 xl:py-6 xl:text-sm">
                     <div className='flex flex-wrap items-center justify-start w-4 h-4 border-[1px] border-[#E1DEE0] rounded-sm xl:w-5 xl:h-5'>
                     </div>
                  </td>

                  <td className="px-4 py-3 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-8 xl:py-6 xl:text-sm">
                     <div className='flex flex-wrap items-center justify-start w-4 h-4 border-[1px] border-[#E1DEE0] rounded-sm xl:w-5 xl:h-5'>
                     </div>
                  </td>

                  <td className="px-4 py-3 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-8 xl:py-6 xl:text-sm">
                     <div className='flex flex-wrap items-center justify-start w-4 h-4 border-[1px] border-[#E1DEE0] rounded-sm xl:w-5 xl:h-5'>
                     </div>
                  </td>

                  <td className="px-4 py-3 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-8 xl:py-6 xl:text-sm">
                     <div className='flex flex-wrap items-center justify-start w-4 h-4 border-[1px] border-[#E1DEE0] rounded-sm xl:w-5 xl:h-5'>
                     </div>
                  </td>
                  
                  <td className="px-4 py-3 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-8 xl:py-6 xl:text-sm">
                     <div className='flex flex-wrap items-center justify-start w-4 h-4 border-[1px] border-[#E1DEE0] rounded-sm xl:w-5 xl:h-5'>
                     </div>
                  </td>
                </tr>


                <tr className='w-full bg-white'>
                  <td className="px-2 pr-8 py-4 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-4 xl:py-6 xl:text-sm">3</td>

                  <td className="py-6 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-8 xl:py-6 xl:text-sm">Publish and approve posts</td>

                  <td className="px-4 py-3 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-8 xl:py-6 xl:text-sm">
                     <div className='flex flex-wrap items-center justify-start w-4 h-4 border-[1px] border-[#E1DEE0] rounded-sm xl:w-5 xl:h-5'>
                     </div>
                  </td>

                  <td className="px-4 py-3 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-8 xl:py-6 xl:text-sm">
                     <div className='flex flex-wrap items-center justify-start w-4 h-4 border-[1px] border-[#E1DEE0] rounded-sm xl:w-5 xl:h-5'>
                     </div>
                  </td>

                  <td className="px-4 py-3 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-8 xl:py-6 xl:text-sm">
                     <div className='flex flex-wrap items-center justify-start w-4 h-4 border-[1px] border-[#E1DEE0] rounded-sm xl:w-5 xl:h-5'>
                     </div>
                  </td>

                  <td className="px-4 py-3 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-8 xl:py-6 xl:text-sm">
                     <div className='flex flex-wrap items-center justify-start w-4 h-4 border-[1px] border-[#E1DEE0] rounded-sm xl:w-5 xl:h-5'>
                     </div>
                  </td>
                  
                  <td className="px-4 py-3 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-8 xl:py-6 xl:text-sm">
                     <div className='flex flex-wrap items-center justify-start w-4 h-4 border-[1px] border-[#E1DEE0] rounded-sm xl:w-5 xl:h-5'>
                     </div>
                  </td>
                </tr>


                <tr className='w-full bg-white'>
                  <td className="px-2 pr-8 py-4 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-4 xl:py-6 xl:text-sm">4</td>

                  <td className="py-6 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-8 xl:py-6 xl:text-sm">Shipment Tracking </td>

                  <td className="px-4 py-3 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-8 xl:py-6 xl:text-sm">
                     <div className='flex flex-wrap items-center justify-start w-4 h-4 border-[1px] border-[#E1DEE0] rounded-sm xl:w-5 xl:h-5'>
                     </div>
                  </td>

                  <td className="px-4 py-3 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-8 xl:py-6 xl:text-sm">
                     <div className='flex flex-wrap items-center justify-start w-4 h-4 border-[1px] border-[#E1DEE0] rounded-sm xl:w-5 xl:h-5'>
                     </div>
                  </td>

                  <td className="px-4 py-3 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-8 xl:py-6 xl:text-sm">
                     <div className='flex flex-wrap items-center justify-start w-4 h-4 border-[1px] border-[#E1DEE0] rounded-sm xl:w-5 xl:h-5'>
                     </div>
                  </td>

                  <td className="px-4 py-3 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-8 xl:py-6 xl:text-sm">
                     <div className='flex flex-wrap items-center justify-start w-4 h-4 border-[1px] border-[#E1DEE0] rounded-sm xl:w-5 xl:h-5'>
                     </div>
                  </td>
                  
                  <td className="px-4 py-3 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-8 xl:py-6 xl:text-sm">
                     <div className='flex flex-wrap items-center justify-start w-4 h-4 border-[1px] border-[#E1DEE0] rounded-sm xl:w-5 xl:h-5'>
                     </div>
                  </td>
                </tr>


                <tr className='w-full bg-white'>
                  <td className="px-2 pr-8 py-4 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-4 xl:py-6 xl:text-sm">5</td>

                  <td className="py-6 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-8 xl:py-6 xl:text-sm">Broadcast Requests</td>

                  <td className="px-4 py-3 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-8 xl:py-6 xl:text-sm">
                     <div className='flex flex-wrap items-center justify-start w-4 h-4 border-[1px] border-[#E1DEE0] rounded-sm xl:w-5 xl:h-5'>
                     </div>
                  </td>

                  <td className="px-4 py-3 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-8 xl:py-6 xl:text-sm">
                     <div className='flex flex-wrap items-center justify-start w-4 h-4 border-[1px] border-[#E1DEE0] rounded-sm xl:w-5 xl:h-5'>
                     </div>
                  </td>

                  <td className="px-4 py-3 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-8 xl:py-6 xl:text-sm">
                     <div className='flex flex-wrap items-center justify-start w-4 h-4 border-[1px] border-[#E1DEE0] rounded-sm xl:w-5 xl:h-5'>
                     </div>
                  </td>

                  <td className="px-4 py-3 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-8 xl:py-6 xl:text-sm">
                     <div className='flex flex-wrap items-center justify-start w-4 h-4 border-[1px] border-[#E1DEE0] rounded-sm xl:w-5 xl:h-5'>
                     </div>
                  </td>
                  
                  <td className="px-4 py-3 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-8 xl:py-6 xl:text-sm">
                     <div className='flex flex-wrap items-center justify-start w-4 h-4 border-[1px] border-[#E1DEE0] rounded-sm xl:w-5 xl:h-5'>
                     </div>
                  </td>
                </tr>



              </tbody>
            </table>
         </div>


         <div className='flex items-center justify-end w-full mt-4 md:mt-8 lg:mt-11 xl:mt-14'>
                <div className='flex items-center justify-center border-[1px] border-[#dddddd] rounded-md h-7 w-24 md:h-8 lg:w-36 xl:h-9 xl:w-40'>
                     <p className='text-[10px] text-[#2196F3] xl:text-sm xl:font-medium'>Cancel</p>
                </div>

                <div className='flex items-center justify-center bg-[#2196F3] rounded-md h-7 w-24 ml-3 md:ml-6 md:h-8 lg:w-36 xl:h-9 xl:w-40'>
                     <p className='text-[10px] text-white xl:text-sm xl:font-medium'>Create Role</p>
                </div>
         </div>

    </div>
  )
}

export default RolesModal
