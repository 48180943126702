import React from 'react'
import Navbar from '../components/general/Navbar'
import logo from '../assets/images/obaLogo.png'
import logo2 from '../assets/images/obaLogo2.png'
import { HiArrowLeft } from "react-icons/hi2";
import { useNavigate } from 'react-router-dom';
import error from '../assets/images/404 image.svg'




const PageNotFound = () => {

  const navigate = useNavigate();

  return (
    <div className='flex flex-col items-center justify-center w-full h-screen font-poppins z-20 bg-cover bg-center bg-[#0F1828] xl:h-full'>
         <div className='flex flex-row items-center justify-center absolute top-0 w-full pt-3'>
              <Navbar logo={logo} logo2={logo2} text2={'#FFFFFF'} linkTexts={'#FFFFFF'} sustain={'#2196F3'} regBackground={'#2196F3'} reg={'#FFFFFF'} />
         </div>

        <div className='flex flex-col items-center justify-start w-full bg-[#0F1828] px-5 lg:px-16 lg:flex-row lg:justify-between xl:px-24 mx-auto max-w-screen-2xl'>
              <div className='flex flex-col items-center justify-start lg:items-start'>
                    <p className='text-[26px] text-center font-bold text-white lg:text-[28px] xl:text-4xl'>
                         Oops...
                    </p>
                    <p className='text-[13px] text-center font-normal text-white pt-3 lg:text-sm xl:text-base xl:pt-5'>
                         Page  not found 
                    </p>
                    <p className='text-[11px] text-center font-normal text-[#d6d6d6] pt-3 w-[70%] lg:text-start lg:w-full lg:text-xs xl:text-sm xl:pt-4 xl:leading-6'>
                         This Page doesn`t exist or was removed!
                         We suggest you go back to home.                    
                    </p>

                    <div onClick={()=>navigate('/')}
                    className='flex items-center justify-center w-[170px] rounded-3xl h-11 bg-[#2196F3] mt-10 text-white cursor-pointer border-[1px] border-[#2196F3] 
                                    hover:bg-transparent lg:w-[185px] xl:h-14 xl:w-[200px] xl:rounded-[30px]'>
                        <HiArrowLeft className='text-[#d6d6d6] xl:text-lg' />
                        <p className='text-[11px] font-normal text-[#d6d6d6] ml-3 lg:text-xs lg:ml-4 xl:text-sm'>
                            Back to home       
                         </p>
                    </div>
              </div>


              <div className='hidden lg:flex items-center justify-end w-full h-full mt-10 xl:mt-24'>
                <img className='w-[476px] xl:w-[626px]'
                src={error} alt='404 error illustration' />
              </div>
        </div> 

    </div>
  )
}

export default PageNotFound
