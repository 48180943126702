import React, { useEffect, useState } from 'react'
import { TfiClose } from "react-icons/tfi";
import { IoIosSend } from "react-icons/io";
import { useDispatch } from 'react-redux';
import { sellerSendComment, sellersGetComments } from '../../../features/seller/SellerAction';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { TfiTime } from "react-icons/tfi";



const SellerChatModal = ({setOpenChat, broadcastDetails}) => {
     const [loadChat, setLoadChat] = useState(false)
     const [loading, setLoading] = useState(false)
     const [chat, setChat] = useState([])
     const [newMessage, setNewMessage] = useState('')
     const [comment, setComment] = useState([])
     const [loadState, setLoadState] = useState('')


     const id = broadcastDetails.broadcast_response.data[0].id
     const data = broadcastDetails.broadcast_response.data[0]

     const chatOrder = chat.slice().reverse();



     const dispatch = useDispatch();

     useEffect(() => {
          dispatch(sellersGetComments(setChat, setLoadChat, id))
          // console.log(data, 'DETAILS')
     }, [dispatch, id, chat, data])


     const values = {'broadcast_response_id': id, 'message': newMessage}

     const clearInput = () => {
          setNewMessage('')
     }

     const handleSend = () => {
          dispatch(sellerSendComment(values, setComment, clearInput, setLoading))
          // console.log(values);
          setLoadState(newMessage)
     }


  return (
    <div className='fixed flex flex-col items-start justify-start bg-white rounded-lg w-[90%] h-[450px] mt-32 py-5 md:w-[50%] lg:w-[35%] xl:w-[27%] xl:mt-44'>
            {/* HEADER */}
           <div className='flex items-center justify-between w-full px-4'>
              <p className='text-sm text-black font-medium'>
                   {data.broadcast.product_name} ({data.quantity} metric tons)
              </p>
              <TfiClose onClick={()=>setOpenChat(false)} className='text-sm' />
           </div>
           <div className='w-full border-[#dddddd] border-[0.4px] opacity-40 mt-2 mb-1'></div>

           
           <div className='flex flex-col items-center justify-center w-full overflow-y-auto no-scrollbar px-4 pb-12'>   
               {/* CHATS */}
               {chatOrder.map((item) => {
                    return(
               <div key={item.id} className={`flex flex-col items-start justify-start w-full ${item.sender === 'seller' && 'items-end'}`}>
                 <div className={`flex flex-col items-start justify-start w-full ${item.sender === 'seller' && 'items-end'}`}>
                     <div className={`flex flex-col items-center justify-start max-w-[60%] bg-[#60b7ff] rounded-[15px] text-black px-4 py-3 mb-1 ${item.sender === 'seller' && 'bg-[#DDDDDD]'}`}>
                          <p className={`text-[10px] text-start font-normal text-black ${item.sender === 'seller' && 'text-end'}`}>
                                {item.message}
                          </p>
                      </div>
                      <p className={`text-[8px] text-start font-normal text-black ml-1`}>
                              {item.created_at.slice(11, 16)}
                      </p>
                 </div> 
               </div>
                    )
               })}

               {loading && 
               <div className={`flex flex-col items-end justify-start w-full`}>
                   <div className={`flex flex-col items-center justify-start max-w-[60%] bg-[#DDDDDD] rounded-[15px] text-black px-4 py-3 mb-1`}>
                        <p className={`text-[10px] text-end font-normal text-black italic`}>
                              {loadState}
                        </p>
                    </div>
                    <div className='flex flex-col items-end w-full px-1'>
                          <TfiTime className='text-sm text-[#696868]' />
                    </div>
               </div> 
               }
               

           </div>
          


           <div className='absolute bottom-3 flex items-center w-full px-4 bg-white pt-1'>
                <input className='flex-1 h-10 rounded-2xl border-[#dddddd] border-[1px] pl-4 text-black font-normal outline-[#2196F3] text-[10px]' 
                placeholder='Type a Message'
                value={newMessage}
                onChange={(e)=>setNewMessage(e.target.value)}
                />
                <div onClick={handleSend}
                className='flex items-center justify-center bg-[#2196F3] rounded-full h-9 w-9 p-2 ml-2'>
                     <IoIosSend className='text-[#ffffff] text-3xl' />
                </div>
           </div>

    </div>
  )
}

export default SellerChatModal
