import React from 'react'
// import logo from '../../assets/images/obaLogo.svg'
import logo from '../../assets/images/logo1.png'
import { FaLinkedin, FaTwitter, FaFacebookSquare } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { PiCopyrightLight } from "react-icons/pi";
import mgLogo from '../../assets/images/mgLogo.svg'
import { Link } from 'react-router-dom';




const Footer = () => {

  return (
    <div className='flex items-center justify-center w-full bg-[#0F1828] px-5 pt-5 pb-6 md:pt-8 md:px-10 lg:px-14 xl:px-24'>
        <div className='flex flex-col items-center justify-start w-full max-w-screen-2xl'>
         {/* FOOTER TOP */}
          <div className='flex flex-col items-start justify-start w-full lg:flex-row lg:justify-between lg:items-start'>
               {/* LOGO AND DESCRIOPTION */}
               <div className='flex flex-col items-start justify-start w-[75%] lg:w-[40%]'>
                    <Link to={{ pathname: '/', search: '?sectionId=home-top'}}>
                        <img
                        className='w-20 h-20 lg:w-28 cursor-pointer lg:h-28 xl:w-32 xl:h-32'
                        src={logo} alt="logo" />
                    </Link>
                    <p className='text-[10px] text-[#D0D5DC] font-normal mt-3 md:w-[50%] lg:w-[80%] lg:text-[10px] xl:text-[12px] xl:mt-3'>
                       Join OAT's platform for Effortless Resource Showcase and Sales, Redefining the Future of Global Trade.
                    </p>

                    <div className='flex flex-col items-start justify-normal mt-5 w-full'>
                        <p className='text-[13px] font-medium text-[#667085] xl:text-[15px] xl:font-semibold'>
                            Head Office
                        </p>
                        <p className='text-[11px] mt-3 text-[#D0D5DC] font-normal xl:text-[12px] xl:mt-3'>
                            Lagos: Bayview estate, Lekki.
                        </p>
                        <p className='text-[11px] mt-2 text-[#D0D5DC] font-normal xl:text-[12px] xl:mt-3'>
                            Kwara: 13A Offa Road, GRA, Ilorin
                        </p>
                    </div>
               </div>

               <div className='flex flex-col items-start justify-start w-full md:flex-row md:justify-between md:items-start lg:mt-0'>
               {/* QUICK LINKS */}
               <div className='flex flex-col items-start justify-start w-full mt-8'>
                     <p className='text-[13px] font-medium text-[#667085] xl:text-[15px] xl:font-semibold'>
                        Quick Links
                     </p>
                     <Link to={{ pathname: '/', search: '?sectionId=faq'}} className='text-[12px] font-normal text-[#FFFFFF] mt-5 xl:text-[14px] xl:mt-6'>
                        FAQ's
                     </Link>
                     <Link to={{ pathname: '/locations', search: '?sectionId=location'}} className='text-[12px] font-normal text-[#FFFFFF] mt-5 xl:text-[14px] xl:mt-6'>
                        Explore
                     </Link>
               </div>


               {/* CONTACT US */}
               <div className='flex flex-col items-start justify-start w-full mt-8 lg:mr-10 xl:mr-14'>
                     <p className='text-[13px] font-medium text-[#667085] xl:text-[15px] xl:font-semibold'>
                        Contact us
                     </p>
                     <p className='text-[12px] font-normal text-[#FFFFFF] mt-5 xl:text-[14px] xl:mt-6'>
                       +234 806 964 6641
                     </p>
                     <p className='text-[12px] font-normal text-[#FFFFFF] mt-5 xl:text-[14px] xl:mt-6'>
                        +234 810 172 0589
                     </p>
               </div>

              
               {/* FOLLOW US */}
               <div className='flex flex-col items-start justify-start w-full mt-8'>
                    <p className='text-[13px] font-medium text-[#667085] xl:text-[15px] xl:font-semibold'>
                       Follow us
                    </p>
                     <a href='https://www.linkedin.com/company/oba-of-all-trade-oat/' rel="noreferrer" target="_blank"
                     className='flex flex-row items-center justify-start mt-5 cursor-pointer xl:mt-6'>
                         <FaLinkedin className='text-white xl:text-xl' />
                         <p className='text-[12px] font-normal text-[#FFFFFF] ml-2 xl:text-[14px]'>
                            LinkedIn
                         </p>
                     </a>

                     <a href='https://x.com/obaofalltrades?t=KJfcXiNzQEcjYLY8XR3grQ&s=09' 
                     className='flex flex-row items-center justify-start mt-5 cursor-pointer xl:mt-6'>
                         <FaTwitter className='text-white xl:text-xl' />
                         <p className='text-[12px] font-normal text-[#FFFFFF] ml-2 xl:text-[14px]'>
                            Twitter
                         </p>
                     </a>
                     
                     <a href='https://www.instagram.com/obaofalltrades?igsh=MXEwaDZmNDY4eG56bA%3D%3D&utm_source=qr' rel="noreferrer" target="_blank"
                     className='flex flex-row items-center justify-start mt-5 cursor-pointer xl:mt-6'>
                         <AiFillInstagram className='text-white xl:text-xl' />
                         <p className='text-[12px] font-normal text-[#FFFFFF] ml-2 xl:text-[14px]'>
                            Instagram
                         </p>
                     </a>

                     <a href='https://web.facebook.com/profile.php?id=61561305238012' rel="noreferrer" target="_blank"
                     className='flex flex-row items-center justify-start mt-5 cursor-pointer xl:mt-6'>
                         <FaFacebookSquare className='text-white xl:text-xl' />
                         <p className='text-[12px] font-normal text-[#FFFFFF] ml-2 xl:text-[14px]'>
                            Facebook
                         </p>
                     </a>

               </div>


               {/* POWERED BY */}
               <div className='flex flex-col items-start justify-start w-full mt-8 lg:items-center'>
                    <p className='text-[13px] font-medium text-[#667085] xl:text-[15px] xl:font-semibold'>
                       Powered by
                    </p>
                    
                    <div className='flex items-center justify-center w-full'>
                       <img className='h-24 w-24 cursor-pointer xl:h-36 xl:w-36'
                       src={mgLogo} alt="Morgen Green Logo" />
                    </div>
               </div>

               </div>

          </div>



          {/* FOOTER BOTTOM */}
          <div className='flex flex-col items-center justify-start w-full mt-5 md:mt-10 lg:mt-14'>
                 <div className='w-full h-[0.4px] bg-[#D0D5DC]'></div>

                 <div className='flex flex-col items-start justify-start w-full mt-3 md:flex-row-reverse md:mt-5 md:mb-3'>
                      <div className='flex flex-row items-center justify-between w-full md:w-[55%]'>
                           <p className='text-[9px] font-light text-[#2196F3] cursor-pointer underline underline-offset-1 xl:text-[12px] xl:underline-offset-2'>
                               Privacy Policy
                           </p>
                           <p className='text-[9px] font-light text-[#2196F3] cursor-pointer underline underline-offset-1 xl:text-[12px] xl:underline-offset-2'>
                               Terms of Service
                           </p>
                           <p className='text-[9px] font-light text-[#2196F3] cursor-pointer underline underline-offset-1 xl:text-[12px] xl:underline-offset-2'>
                               Cookies Settings
                           </p>
                      </div>

                      <div className='flex flex-row items-center justify-center w-full mt-8 md:mt-0 md:justify-start'>
                         <PiCopyrightLight className='text-[#D0D5DC] mr-1' />
                         <p className='text-[10px] font-light text-[#D0D5DC] xl:text-[12px]'>
                           2023 Oba Of All Trades. All rights reserved.
                         </p>
                      </div>
                 </div>

          </div>

         </div>
    </div>
  )
}

export default Footer