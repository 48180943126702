import React, { useState } from 'react'
import { IoAdd } from "react-icons/io5";
import { VscEdit } from "react-icons/vsc";
import { RiDeleteBin6Line } from "react-icons/ri";
import RolesModal from './modal/RolesModal';



const Roles = () => {
    const [openModal, setOpenModal] = useState(false)

  return (
    <div className='flex flex-col items-center justify-start w-full'>
    <div className='flex flex-col items-center justify-start w-full py-6 px-6 mt-24 md:mt-20 lg:mt-0 lg:pt-[100px] xl:px-14 xl:pt-[75px] xl:mt-10'>
         <div className='flex flex-row items-center justify-start w-full'>
              <p className='text-[10px] text-[#808080] font-medium xl:text-[13px]'>
                   Dashboard / <span className='text-[#2196F3]'>Roles</span> 
              </p>
         </div>

         <div className='flex items-center justify-end w-full mt-3 md:mt-0 xl:mt-1'>
             <div onClick={()=>setOpenModal(true)}
             className='flex items-center justify-center h-7 w-28 rounded-md bg-[#2196F3] lg:w-[115px] xl:h-9 xl:w-36'>
                 <IoAdd className='text-base text-white xl:text-lg' />
                 <p className='text-[10px] font-medium text-white ml-1 lg:ml-2 xl:text-[13px]'>
                     New user Role
                 </p>
             </div>
         </div>

         <div className="overflow-x-auto w-full no-scrollbar mt-8">
            <table className="table-auto border-collapse w-full">
              <thead className=''>
                <tr className='w-full'>
                  <th className="px-4 py-3 text-[10px] border-b-[1px] border-[#E1DEE0] font-medium text-start text-[#667085] md:py-3 lg:py-3 xl:px-8 xl:py-4 xl:text-sm">
                    <p className=''></p>
                  </th>

                  <th className="px-4 py-3 text-[10px] border-b-[1px] border-[#E1DEE0] font-medium text-start text-[#667085] md:py-3 lg:py-3 xl:px-8 xl:py-4 xl:text-sm">
                    <p className='w-[120px]'>
                         Company
                    </p>
                  </th>

                  <th className="px-4 py-3 text-[10px] border-b-[1px] border-[#E1DEE0] font-medium text-start text-[#667085] md:py-3 lg:py-3 xl:px-8 xl:py-4 xl:text-sm">
                    <p className='w-[400px]'>
                         All Permissions
                    </p>
                  </th>

                  <th className="px-4 py-3 text-[10px] border-b-[1px] border-[#E1DEE0] font-medium text-start text-[#667085] md:py-3 lg:py-3 xl:px-8 xl:py-4 xl:text-sm">
                    <p className='w-[60px]'>
                         Action
                    </p>
                  </th>

                </tr>
              </thead>

              <tbody>
                <tr className='w-full bg-white'>
                  <td className="px-4 pr-10 py-6 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-8 xl:py-6 xl:text-sm">1</td>
                  <td className="px-4 py-6 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-8 xl:py-6 xl:text-sm">Portfolio Manager</td>
                  <td className="px-4 py-6 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-8 xl:py-6 xl:text-sm">
                     <div className='flex flex-wrap items-center justify-start w-full'>
                           <div className={`flex items-center justify-center px-[10px] py-1 bg-[#F9F5FF] rounded-xl mr-2 mt-1`}>
                               <p className='text-[9px] text-[#6941C6] xl:text-xs'>
                                  Manage Commodities
                               </p>
                           </div>
                           <div className={`flex items-center justify-center px-[10px] py-1 bg-[#EFF8FF] rounded-xl mr-2 mt-1`}>
                               <p className='text-[9px] text-[#175CD3] xl:text-xs'>
                                  Shipment Tracking Update
                               </p>
                           </div>
                           <div className={`flex items-center justify-center px-[10px] py-1 bg-[#EEF4FF] rounded-xl mr-2 mt-1`}>
                               <p className='text-[9px] text-[#3538CD] xl:text-xs'>
                                   Create Roles
                               </p>
                           </div>
                           <div className={`flex items-center justify-center h-4 bg-[#F2F4F7] rounded-md mt-1 px-1`}>
                               <p className='text-[10px] text-[#344054] xl:text-xs'>
                                  +2
                               </p>
                           </div>
                     </div>
                  </td>
                  <td className="pr-4 py-6 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:pr-8 xl:py-6 xl:text-sm">
                     <div className='flex items-start justify-center w-full'>
                         <VscEdit className='text-base xl:text-2xl' />
                         <RiDeleteBin6Line className='text-base text-red-400 ml-4 xl:text-2xl xl:ml-6' />
                     </div>
                  </td>
                </tr>


                <tr className='w-full bg-white'>
                  <td className="px-4 pr-10 py-6 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-8 xl:py-6 xl:text-sm">2</td>
                  <td className="px-4 py-6 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-8 xl:py-6 xl:text-sm">Finance Team</td>
                  <td className="px-4 py-6 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-8 xl:py-6 xl:text-sm">
                     <div className='flex flex-wrap items-center justify-start w-full'>
                           <div className={`flex items-center justify-center px-[10px] py-1 bg-[#EFF8FF] rounded-xl mr-2 mt-1`}>
                               <p className='text-[9px] text-[#175CD3] xl:text-xs'>
                                  Shipment Tracking Update
                               </p>
                           </div>
                           <div className={`flex items-center justify-center px-[10px] py-1 bg-[#EEF4FF] rounded-xl mr-2 mt-1`}>
                               <p className='text-[9px] text-[#3538CD] xl:text-xs'>
                                   Create Roles
                               </p>
                           </div>
                           <div className={`flex items-center justify-center h-4 bg-[#F2F4F7] rounded-md mt-1 px-1`}>
                               <p className='text-[10px] text-[#344054] xl:text-xs'>
                                  +2
                               </p>
                           </div>
                     </div>
                  </td>
                  <td className="pr-4 py-6 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:pr-8 xl:py-6 xl:text-sm">
                     <div className='flex items-start justify-center w-full'>
                         <VscEdit className='text-base xl:text-2xl' />
                         <RiDeleteBin6Line className='text-base text-red-400 ml-4 xl:text-2xl xl:ml-6' />
                     </div>
                  </td>
                </tr>


                <tr className='w-full bg-white'>
                  <td className="px-4 pr-10 py-6 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-8 xl:py-6 xl:text-sm">3</td>
                  <td className="px-4 py-6 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-8 xl:py-6 xl:text-sm">Operations & Quality Control</td>
                  <td className="px-4 py-6 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-8 xl:py-6 xl:text-sm">
                     <div className='flex flex-wrap items-center justify-start w-full'>
                           <div className={`flex items-center justify-center px-[10px] py-1 bg-[#F9F5FF] rounded-xl mr-2 mt-1`}>
                               <p className='text-[9px] text-[#6941C6] xl:text-xs'>
                                  Manage Commodities
                               </p>
                           </div>
                           <div className={`flex items-center justify-center px-[10px] py-1 bg-[#EFF8FF] rounded-xl mr-2 mt-1`}>
                               <p className='text-[9px] text-[#175CD3] xl:text-xs'>
                                  Shipment Tracking Update
                               </p>
                           </div>
                           <div className={`flex items-center justify-center px-[10px] py-1 bg-[#EEF4FF] rounded-xl mr-2 mt-1`}>
                               <p className='text-[9px] text-[#3538CD] xl:text-xs'>
                                   Create Roles
                               </p>
                           </div>
                           <div className={`flex items-center justify-center h-4 bg-[#F2F4F7] rounded-md mt-1 px-1`}>
                               <p className='text-[10px] text-[#344054] xl:text-xs'>
                                  +2
                               </p>
                           </div>
                     </div>
                  </td>
                  <td className="pr-4 py-6 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:pr-8 xl:py-6 xl:text-sm">
                     <div className='flex items-start justify-center w-full'>
                         <VscEdit className='text-base xl:text-2xl' />
                         <RiDeleteBin6Line className='text-base text-red-400 ml-4 xl:text-2xl xl:ml-6' />
                     </div>
                  </td>
                </tr>


                <tr className='w-full bg-white'>
                  <td className="px-4 pr-10 py-6 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-8 xl:py-6 xl:text-sm">4</td>
                  <td className="px-4 py-6 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-8 xl:py-6 xl:text-sm">Customer Support</td>
                  <td className="px-4 py-6 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-8 xl:py-6 xl:text-sm">
                     <div className='flex flex-wrap items-center justify-start w-full'>
                           <div className={`flex items-center justify-center px-[10px] py-1 bg-[#F9F5FF] rounded-xl mr-2 mt-1`}>
                               <p className='text-[9px] text-[#6941C6] xl:text-xs'>
                                  Manage Commodities
                               </p>
                           </div>
                           <div className={`flex items-center justify-center px-[10px] py-1 bg-[#EFF8FF] rounded-xl mr-2 mt-1`}>
                               <p className='text-[9px] text-[#175CD3] xl:text-xs'>
                                  Shipment Tracking Update
                               </p>
                           </div>
                           <div className={`flex items-center justify-center h-4 bg-[#F2F4F7] rounded-md mt-1 px-1`}>
                               <p className='text-[10px] text-[#344054] xl:text-xs'>
                                  +2
                               </p>
                           </div>
                     </div>
                  </td>
                  <td className="pr-4 py-6 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:pr-8 xl:py-6 xl:text-sm">
                     <div className='flex items-start justify-center w-full'>
                         <VscEdit className='text-base xl:text-2xl' />
                         <RiDeleteBin6Line className='text-base text-red-400 ml-4 xl:text-2xl xl:ml-6' />
                     </div>
                  </td>
                </tr>


                <tr className='w-full bg-white'>
                  <td className="px-4 pr-10 py-6 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-8 xl:py-6 xl:text-sm">5</td>
                  <td className="px-4 py-6 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-8 xl:py-6 xl:text-sm">IT Department</td>
                  <td className="px-4 py-6 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:px-8 xl:py-6 xl:text-sm">
                     <div className='flex flex-wrap items-center justify-start w-full'>
                           <div className={`flex items-center justify-center px-[10px] py-1 bg-[#F9F5FF] rounded-xl mr-2 mt-1`}>
                               <p className='text-[9px] text-[#6941C6] xl:text-xs'>
                                   Roles
                               </p>
                           </div>
                           <div className={`flex items-center justify-center px-[10px] py-1 bg-[#EFF8FF] rounded-xl mr-2 mt-1`}>
                               <p className='text-[9px] text-[#175CD3] xl:text-xs'>
                                  Shipment Tracking Update
                               </p>
                           </div>
                           <div className={`flex items-center justify-center px-[10px] py-1 bg-[#EEF4FF] rounded-xl mr-2 mt-1`}>
                               <p className='text-[9px] text-[#3538CD] xl:text-xs'>
                                   Create Roles
                               </p>
                           </div>
                           <div className={`flex items-center justify-center h-4 bg-[#F2F4F7] rounded-md mt-1 px-1`}>
                               <p className='text-[10px] text-[#344054] xl:text-xs'>
                                  +2
                               </p>
                           </div>
                     </div>
                  </td>
                  <td className="pr-4 py-6 border-b-[1px] border-[#E1DEE0] text-[10px] font-medium md:py-4 lg:py-5 xl:pr-8 xl:py-6 xl:text-sm">
                     <div className='flex items-start justify-center w-full'>
                         <VscEdit className='text-base xl:text-xl' />
                         <RiDeleteBin6Line className='text-base text-red-400 ml-4 xl:text-xl xl:ml-6' />
                     </div>
                  </td>
                </tr>

              </tbody>
            </table>
         </div>

    </div>

       {openModal &&
       <div onClick={()=>setOpenModal(false)} className='fixed h-full w-full bg-black opacity-30'></div>
       }

       {openModal && <RolesModal />}

    </div>
  )
}

export default Roles
