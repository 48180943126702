import React, { useEffect, useState } from 'react'
import { CiSearch } from "react-icons/ci";
import { HiSortDescending } from "react-icons/hi";
import { IoFilterSharp } from "react-icons/io5";
import metal1 from '../../../assets/images/Metal1.svg'
import { MdOutlineFileCopy } from "react-icons/md";
import { GoDotFill } from "react-icons/go";
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from "react-icons/io";
import { SearchCommodity, purchaseRequestAction } from '../../../features/buyer/BuyerActions';
import { useDispatch } from 'react-redux';




const ClosedPurchase = () => {

    const dispatch = useDispatch();

    const [closedPurchase, setClosedPurchase] = useState([]);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [current, setCurrent] = useState(1)


    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [search, setSearch] = useState(false);      
    
    const itemsPerPage = 10; 
    const totalPages = Math.ceil(total / itemsPerPage); // Calculate total pages
    const [currentPage, setCurrentPage] = useState(1); // Current page state

  
    // Calculate start and end index of items for current page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
  
    // Get current page items
    const currentPageItems = closedPurchase.slice(startIndex, endIndex);

    const handleInputChange = (event) => {
      setSearchTerm(event.target.value);
    };

//     useEffect(() => {
//         dispatch(purchaseRequestAction(setClosedPurchase, setLoading, setTotal))

//     }, [dispatch])

//     useEffect(()=>{
//       if (searchTerm !== '') {
//           dispatch(SearchCommodity(setSearchResults, searchTerm))
//           setSearch(true)
//         } else {
//           setSearchResults([]);
//           setSearch(false)
//         }
//      },[dispatch, searchTerm])



    const handlePageChange = (page) => {
      dispatch(purchaseRequestAction(setClosedPurchase, setLoading, setTotal, page))
      setCurrent(page)
    };



  return (
    <div className='relative flex flex-col items-center justify-start w-full h-[29.5rem] border-[1px] border-[#dddddd] rounded-lg mt-10 py-2 pb-5 md:h-[27.5rem] 
                    lg:h-[27.1rem] xl:h-[27.6rem]'>
               <div className='flex flex-col items-center w-full bg-[#F9FAFB] px-3 py-2 md:flex-row md:justify-between'>
                   <div className='relative w-full'>
                       <input 
                       className='h-9 w-full z-20 rounded-md border-[1px] border-[#DDE1E6] pl-9 text-xs font-light outline-[#2196F3] md:w-[80%] lg:h-8 xl:h-10 xl:w-[65%] xl:text-sm'
                       placeholder='Search'
                       value={searchTerm}
                       onChange={handleInputChange}
                       />
                       <CiSearch className='absolute top-[11px] left-3 text-base text-[#818181] xl:text-xl' />
                   </div>

                   <div className='flex flex-row items-center justify-between w-full mt-2 md:justify-end md:mt-0'>
                       <div className='flex flex-row items-center justify-center bg-white border-[#dddddd] border-[1px] rounded px-2 py-1 md:py-[5px]
                        md:rounded-md md:px-4 xl:py-2'>
                            <HiSortDescending className='text-sm md:text-base' />
                            <p className='text-black text-[10px] font-normal ml-1 xl:text-xs'>
                                Sort
                            </p>
                       </div>

                       <div className='flex flex-row items-center justify-center bg-white border-[#dddddd] border-[1px] rounded px-2 py-1 md:py-[5px] 
                       md:rounded-md md:px-4 md:ml-3 xl:py-2'>
                            <IoFilterSharp className='text-sm md:text-base' />
                            <p className='text-black text-[10px] font-medium ml-2 xl:text-xs'>
                                Filters
                            </p>
                       </div>
                   </div>

               </div>

               <div className='w-full mt-4 overflow-x-auto no-scrollbar'>
               <table className='w-[800px] px-5 md:w-full'>
                   <thead className='border-separate border-b border-slate-[#dddddd]'>
                   <tr tr className='w-full mb-1'>
                       <td className='text-[11px] text-[#667085] pl-4'>S/N</td>
                       <td className='text-[11px] text-[#667085]'>Commodity</td>
                       <td className='text-[11px] text-[#667085]'>Purchase ID</td>
                       <td className='text-[11px] text-[#667085]'>Tracking Number</td>
                       <td className='text-[11px] text-[#667085]'>Status</td>
                       <td className='text-[11px] text-[#667085]'>Date/Time</td>
                   </tr>
                   </thead>

                   {!search  
                   ?<tbody className='w-full'>
                        {currentPageItems.map((item) => {
                          return(
                        <tr key={item.id} className='h-14 hover:bg-[#EEEEEE]'>
                        
                            <td className="border-b text-[10px] pl-6 font-medium">{item.id}</td>

                            <td className="flex items-center justify-start h-14 border-b text-[10px] font-medium pr-14">
                                 <img className='w-9 rounded-lg'
                                 src={metal1} alt='metal' />
                                 <p className='pl-4'>{item.name}</p>
                            </td>

                            <td className="border-b text-[10px] font-medium">
                                 <div className='flex items-center justify-start'>
                                 <p className=''>AD33342222</p>
                                     <MdOutlineFileCopy className='text-[#D0D5DD] ml-2 text-xs' />
                                 </div>
                            </td>

                            <td className="border-b text-[10px] font-medium">
                                 <div className='flex items-center justify-start'>
                                 <p className=''>{item.tracking_status}</p>
                                     <MdOutlineFileCopy className='text-[#D0D5DD] ml-2 text-xs' />
                                 </div>
                            </td>

                            <td className="border-b text-[10px] font-medium w-32">
                                <div className={`flex items-center justify-center w-24 rounded-lg h-5
                                     ${item.status === 'pending_verification' || item.status === 'In-transit' ? 'bg-[#FFFAEB]' : item.status === 'completed' && 'bg-[#ECFDF3]' }`}>
                                    <GoDotFill className={`text-[9px] xl:text-[10px] 
                                     ${item.status === 'pending_verification' || item.status === 'In-transit' ? 'text-[#F79009]' : item.status === 'completed' && 'text-[#12B76A]' }`} />

                                    <p className={`text-[9px] font-medium ml-1 xl:text-[10px] 
                                     ${item.status === 'pending_verification' || item.status === 'In-transit' ? 'text-[#F79009]' : item.status === 'completed' && 'text-[#027A48]' }`}>
                                      {item.status}
                                    </p>
                               </div>
                            </td>

                            <td className="border-b text-[9px] font-medium">
                                 <div className='flex flex-col items-start justify-start w-14'>
                                        <p className=''>{item.created_at.slice(0, 10)}</p>
                                        <p className='text-gray-400'>{item.created_at.slice(12, 16)}</p>
                                 </div>
                            </td>

                            <td className="border-b text-[10px] font-medium">
                                 <div className='flex justify-center items-center h-7 w-20 border-[#D0D5DD] border-[1px] bg-[#F2F4F7] rounded-md'>
                                        <p className='text-[#2196F3]'>View Order</p>
                                 </div>
                            </td>

                        </tr>
                        )
                        })}
                   </tbody>
                   :<tbody className='w-full'>
                        {searchResults.map((item) => {
                          return(
                        <tr key={item.id} className='h-14 hover:bg-[#EEEEEE]'>
                        
                            <td className="border-b text-[10px] pl-6 font-medium">{item.id}</td>

                            <td className="flex items-center justify-start h-14 border-b text-[10px] font-medium pr-14">
                                 <img className='w-9 rounded-lg'
                                 src={metal1} alt='metal' />
                                 <p className='pl-4'>{item.name}</p>
                            </td>

                            <td className="border-b text-[10px] font-medium">
                                 <div className='flex items-center justify-start'>
                                 <p className=''>AD33342222</p>
                                     <MdOutlineFileCopy className='text-[#D0D5DD] ml-2 text-xs' />
                                 </div>
                            </td>

                            <td className="border-b text-[10px] font-medium">
                                 <div className='flex items-center justify-start'>
                                 <p className=''>{item.tracking_status}</p>
                                     <MdOutlineFileCopy className='text-[#D0D5DD] ml-2 text-xs' />
                                 </div>
                            </td>

                            <td className="border-b text-[10px] font-medium w-32">
                                  <div className={`flex items-center justify-center w-24 rounded-lg h-5
                                       ${item.status === 'pending_verification' || item.status === 'In-transit' ? 'bg-[#FFFAEB]' : item.status === 'completed' && 'bg-[#ECFDF3]' }`}>
                                      <GoDotFill className={`text-[9px] xl:text-[10px] 
                                       ${item.status === 'pending_verification' || item.status === 'In-transit' ? 'text-[#F79009]' : item.status === 'completed' && 'text-[#12B76A]' }`} />

                                      <p className={`text-[9px] font-medium ml-1 xl:text-[10px] 
                                       ${item.status === 'pending_verification' || item.status === 'In-transit' ? 'text-[#F79009]' : item.status === 'completed' && 'text-[#027A48]' }`}>
                                        {item.status}
                                      </p>
                                 </div>
                            </td>

                            <td className="border-b text-[9px] font-medium">
                                 <div className='flex flex-col items-start justify-start w-14'>
                                        <p className=''>{item.created_at.slice(0, 10)}</p>
                                        <p className='text-gray-400'>{item.created_at.slice(12, 16)}</p>
                                 </div>
                            </td>

                            <td className="border-b text-[10px] font-medium">
                                 <div className='flex justify-center items-center h-7 w-20 border-[#D0D5DD] border-[1px] bg-[#F2F4F7] rounded-md'>
                                        <p className='text-[#2196F3]'>View Order</p>
                                 </div>
                            </td>

                        </tr>
                        )
                        })}
                   </tbody>
                    }
               </table>
               </div>

               {/* PAGINATION */}
               <div className='absolute bottom-5 flex flex-row iems-center justify-between bg-white pt-3 w-full px-5'>
                  <button onClick={() => handlePageChange(current - 1)} disabled={current === 1}
                    className='flex flex-row items-center border-[#dddddd] border-[1px] rounded-md h-7 px-2'>
                         <IoIosArrowRoundBack className='text-[#344054] text-lg' />
                         <p className='text-[#344054] text-[10px] ml-1 font-medium'>Previous</p>
                    </button>

                    <div className='flex items-end justify-center w-full'>
                           {Array.from(Array(totalPages), (e, i) => {
                             return (
                               <div key={i} className={`flex items-center justify-center text-[10px] px-[8px] py-1 mx-[2px] rounded-[4px] md:text-xs lg:mx-1 xl:text-xs xl:mx-[6px] 
                                                        ${current === (i + 1) && 'bg-[#F3D7C7] text-[#2196F3]'}`}>
                                 <p href="#" onClick={() => handlePageChange(i + 1)}>
                                   {i + 1}
                                 </p>
                               </div>
                             );
                           })}
                      </div>

                    <button onClick={() => handlePageChange(current + 1)} disabled={current === totalPages}
                    className='flex flex-row items-center border-[#dddddd] border-[1px] rounded-md h-7 px-2'>
                         <p className='text-[#344054] text-[10px] font-medium'>Next</p>
                         <IoIosArrowRoundForward className='text-[#344054] text-lg ml-1' />
                      </button>

                </div>

        </div>
  )
}

export default ClosedPurchase
