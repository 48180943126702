import React, { useEffect } from 'react'
import Footer from '../components/general/Footer'
import { useLocation } from 'react-router-dom';
import SourcingIntro from '../components/sourcing/SourcingIntro';
import SourcingSection2 from '../components/sourcing/SourcingSection2';
import MetaTags from '../MetaTags';


const Sourcing = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  return (
    <div className='flex flex-col items-center justify-start mx-auto max-w-screen-2xl'>
        <MetaTags
        title="Sourcing policy" 
        description="Find out about our sourcing policy" 
        link="/sourcing"
         />
         <SourcingIntro />
         <SourcingSection2 />
         <Footer />
    </div>
  )
}

export default Sourcing
