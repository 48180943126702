import React, { useEffect } from 'react'
import Footer from '../components/general/Footer'
import { useLocation } from 'react-router-dom';
import SustainIntro from '../components/health/SustainIntro';
import HealthSection2 from '../components/health/HealthSection2';
import HealthSection3 from '../components/health/HealthSection3';
import HealthSection4 from '../components/health/HealthSection4';
import MetaTags from '../MetaTags';

const Health = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  return (
    <div className='flex flex-col items-center justify-start mx-auto max-w-screen-2xl'>
        <MetaTags
        title="OAT Health, Safety and Environment policy page" 
        description="Check out our health policy" 
        link="/health"
         />
         <SustainIntro />
         <HealthSection2 />
         <HealthSection3 />
         <HealthSection4 />
         <Footer />
    </div>
  )
}

export default Health
