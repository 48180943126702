import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL

      // RECENT PURCHASE REQUESTS
      export const recentSupplyRequestAction = (setRecetSupplyRequest, setLoading, setError, setTotal, page) => async () => {
        setLoading(true)
        const loginToken =  localStorage.getItem('token');
        const headers = {
          'Authorization': `Bearer ${loginToken}`,
        };
        try{
          const response = await axios.get(`${BASE_URL}/seller/supply-request?page=${page}`, { headers });
          if (response.status === 200) {
            setRecetSupplyRequest(response.data.data.supply_request.data)
            setTotal(response.data.data.supply_request.total)
              // console.log(response.data.data.supply_request.data)
          } else if (response.status !== 200) {
            console.log('request failed status code:', response.status);
          } 
        } catch(error) {
            if (error.response) {
                console.log(error.response)
                console.error('API Error:',error.response.status);
            } else if (error.request) {
              console.log(error.response.data.data.message);
              setError('Please check your internet connection...')
            } 
          };
        
          setLoading(false)
      };




          // HOME PAGE METRICS
    export const metricsData = (setMetrics, setLoadMetrics) => async () => {
      setLoadMetrics(true)
      const loginToken =  localStorage.getItem('token');
      const headers = {
        'Authorization': `Bearer ${loginToken}`,
      };
      try{
        const response = await axios.get(`${BASE_URL}/seller/supply-request/metrics`, { headers });
        if (response.status === 200) {
          // console.log(response.data.data)
          setMetrics(response.data.data)
        } else if (response.status !== 200) {
          console.log('request failed status code:', response.status);
        } 
      } catch(error) {
          if (error.response.data.message) {
              console.log(error.response)
              console.error('API Error:',error.response.status);
          } else if (error.request) {
            console.log(error.response.data.data.message);
          //   setError('Please check your internet connection...')
          } 
        };
      
        setLoadMetrics(false)
    };



          // SEARCH COMMODITY
    export const sellerSearchCommodity = (setSearchResults, searchTerm) => async () => {
        const loginToken =  localStorage.getItem('token');
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${loginToken}`,
          };
          try {
            const response = await axios.get(`${BASE_URL}/seller/supply-request?limit=1&page=2&filter=open&sort=asc&start_date=2024-02-01&end_date=2024-03-28&search=${searchTerm}`, { headers },{
                params: {
                    limit: 1,
                    page: 2,
                    filter: 'open',
                    sort: 'asc',
                    search: searchTerm,
                    start_date: '2024-02-01',
                    end_date: '2024-03-28'
                  }
            });
            setSearchResults(response.data.data.supply_request.data);
            // console.log(response.data);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
      };


          // BROADCAST EVENT LISTS
    export const getBroadcasts = (setActiveRequest, setLoading, setError, setTotal, page) => async () => {
      setLoading(true)
      const loginToken =  localStorage.getItem('token');
      const headers = {
        'Authorization': `Bearer ${loginToken}`,
      };
      try{
        const response = await axios.get(`${BASE_URL}/seller/broadcast?page=${page}`, { headers });
        if (response.status === 200) {
          setActiveRequest(response.data.data.broadcast.data)
          setTotal(response.data.data.broadcast.total)
          // console.log(response.data.data)
        } else if (response.status !== 200) {
          console.log('request failed status code:', response.status);
        } 
      } catch(error) {
          if (error.response.data.message) {
              console.log(error.response)
              console.error('API Error:',error.response.status);
          } else if (error.request) {
            console.log(error.response.data.data.message);
            setError('Please check your internet connection...')
          } 
        };
      
        setLoading(false)
    };

          // BROADCAST RESPONSE EVENT LISTS
    export const getBroadcastsResponse = (setresponse, setLoading, setError, setTotal, page) => async () => {
      setLoading(true)
      const loginToken =  localStorage.getItem('token');
      const headers = {
        'Authorization': `Bearer ${loginToken}`,
      };
      try{
        const response = await axios.get(`${BASE_URL}/seller/broadcast-response?page=${page}`, { headers });
        if (response.status === 200) {
          setresponse(response.data.data.broadcast_response.data)
          setTotal(response.data.data.broadcast_response.total)
          // console.log(response.data.data.broadcast_response.data, 'RESPONSE')
        } else if (response.status !== 200) {
          console.log('request failed status code:', response.status);
        } 
      } catch(error) {
          if (error.response.data.message) {
              console.log(error.response)
              console.error('API Error:',error.response.status);
          } else if (error.request) {
            console.log(error.response.data.data.message);
            setError('Please check your internet connection...')
          } 
        };
      
        setLoading(false)
    };




    // SELLER BROADCASTS
    export const searchBroadcastList = (setSearchResults, searchTerm) => async () => {
      const loginToken =  localStorage.getItem('token');
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${loginToken}`,
        };
        try {
          const response = await axios.get(`${BASE_URL}/seller/broadcast?start_date=2024-02-01&end_date=2024-03-28&search=${searchTerm}`, { headers },{
              params: {
                  limit: 2,
                  page: 1,
                  filter: 'open',
                  sort: 'asc',
                  search: searchTerm,
                  start_date: '2024-02-01',
                  end_date: '2024-03-28',
                }
          });
          setSearchResults(response.data.data.broadcast.data);
          // console.log(response.data.data.broadcast.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };

    // SELLER BROADCASTS RESPONSE
    export const searchBroadcastResponseList = (setSearchResults, searchTerm) => async () => {
      const loginToken =  localStorage.getItem('token');
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${loginToken}`,
        };
        try {
          const response = await axios.get(`${BASE_URL}/seller/broadcast-response?start_date=2024-02-01&end_date=2024-03-28&search=${searchTerm}`, { headers },{
              params: {
                  limit: 2,
                  page: 1,
                  filter: 'open',
                  sort: 'asc',
                  search: searchTerm,
                  start_date: '2024-02-01',
                  end_date: '2024-03-28',
                }
          });
          setSearchResults(response.data.data.broadcast_response.data);
          // console.log(response.data.data.broadcast_response.data);
        } catch (error) {
          console.error('Error fetching data:', error);
          console.log(error.response);
        }
    };



    //BROADCAST DETAILS
    export const broadcastDetails = (setBroadcastDetails, setLoading, setError, item) => async () => {
      setLoading(true)
      const loginToken =  localStorage.getItem('token');
      const headers = {
        'Authorization': `Bearer ${loginToken}`,
      };
      try{
        const response = await axios.get(`${BASE_URL}/seller/broadcast/${item}`, { headers });
        if (response.status === 200) {
          setBroadcastDetails(response.data.data)
          // console.log(response.data.data, 'Hello world1')
        } else if (response.status !== 200) {
          console.log('request failed status code:', response.status);
        } 
      } catch(error) {
          if (error.response.data.message) {
              console.log(error.response)
              console.error('API Error:',error.response.status);
          } else if (error.request) {
            console.log(error.response.data.data.message);
            setError('Please check your internet connection...')
          } 
        };
      
        setLoading(false)
    };

    

    //BROADCAST RESPONSE DETAILS
    export const broadcastResponseDetails = (setBroadcastDetails, setLoading, setError, item) => async () => {
      setLoading(true)
      const loginToken =  localStorage.getItem('token');
      const headers = {
        'Authorization': `Bearer ${loginToken}`,
      };
      try{
        const response = await axios.get(`${BASE_URL}/seller/broadcast-response/${item}`, { headers });
        if (response.status === 200) {
          setBroadcastDetails(response.data.data)
          // console.log(response.data.data, 'Hello world1')
        } else if (response.status !== 200) {
          console.log('request failed status code:', response.status);
        } 
      } catch(error) {
          if (error.response.data.message) {
              console.log(error.response)
              console.error('API Error:',error.response.status);
          } else if (error.request) {
            console.log(error.response.data.data.message);
            setError('Please check your internet connection...')
          } 
        };
      
        setLoading(false)
    };


    // SELLER PROFILE INFO
    export const SellerProfileActions = (setUser) => async () => {
      // setLoading(true)
      const loginToken =  localStorage.getItem('token');
      const headers = {
        'Authorization': `Bearer ${loginToken}`,
      };
      try{
        const response = await axios.get(`${BASE_URL}/user`, { headers });
        if (response.status === 200) {
          console.log(response.data)
          setUser(response.data)
        } else if (response.status !== 200) {
          console.log('request failed status code:', response.status);
        } 
      } catch(error) {
          if (error.response.data.message) {
              console.log(error.response)
              console.error('API Error:',error.response.status);
          } else if (error.request) {
            console.log(error.response.data.data.message);
          //   setError('Please check your internet connection...')
          } 
        };
      
      //   setLoading(false)
    };


    export const saveSellerProfile = (values, setLoading, setEdit) => async () => {
      setLoading(true)
      const loginToken =  localStorage.getItem('token');
      const headers = {
        'Authorization': `Bearer ${loginToken}`,
      };
      try{
        const response = await axios.put(`${BASE_URL}/seller`, values, { headers });
        if (response.status === 200) {
          // console.log('profile uploaded successfully')
          setEdit(true)
        } else if (response.status !== 200) {
          console.log('profile upload failed with status code:', response.status);
        } 
      } catch(error) {
          if (error.response.data.message) {
              console.log(error.response)
              console.error('API Error:',error.response.status);
          } else if (error.request) {
            console.log(error.response.data.data.message);
            // The request was made but no response was received (e.g., network issue)
            console.error('Network Error:', error.request);
          } 
        };
      
        setLoading(false)
    };



        // NOTIFICATIONS
        export const sellerNotifications = (setData, setLoading) => async () => {
          setLoading(true)
          const loginToken =  localStorage.getItem('token');
          const headers = {
            'Authorization': `Bearer ${loginToken}`,
          };
          try{
            const response = await axios.get(`${BASE_URL}/user/notification`, { headers });
            if (response.status === 200) {
              // console.log(response.data.data.notification.data)
              setData(response.data.data.notification.data)
            } else if (response.status !== 200) {
              console.log('request failed status code:', response.status);
            } 
          } catch(error) {
              if (error.response.data.message) {
                  console.log(error.response)
                  console.error('API Error:',error.response.status);
              } else if (error.request) {
                console.log(error.response.data.data.message);
              //   setError('Please check your internet connection...')
              } 
            };
            setLoading(false)
        };


        // UNREAD NOTIFICATIONS
        export const sellersUnreadNot = (setUnread, setLoadUnread) => async () => {
          setLoadUnread(true)
          const loginToken =  localStorage.getItem('token');
          const headers = {
            'Authorization': `Bearer ${loginToken}`,
          };
          try{
            const response = await axios.get(`${BASE_URL}/user/notification/unread-count`, { headers });
            if (response.status === 200) {
              // console.log(response.data.data)
              setUnread(response.data.data)
            } else if (response.status !== 200) {
              console.log('request failed status code:', response.status);
            } 
          } catch(error) {
              if (error.response.data.message) {
                  console.log(error.response)
                  console.error('API Error:',error.response.status);
              } else if (error.request) {
                console.log(error.response.data.data.message);
              //   setError('Please check your internet connection...')
              } 
            };
            setLoadUnread(false)
        };


        // MARK NOTIFICATIONS AS READ
        export const sellersmarkUnreadNot = (item) => async () => {
          // setLoadUnread(true)
          const loginToken =  localStorage.getItem('token');
          const headers = {
            'Authorization': `Bearer ${loginToken}`,
          };
          try{
            const response = await axios.patch(`${BASE_URL}/user/notification/${item}`, { headers });
            if (response.status === 200) {
              console.log(response.data.data)
              // setUnread(response.data.data)
            } else if (response.status !== 200) {
              console.log('request failed status code:', response.status);
            } 
          } catch(error) {
              if (error.response.data.message) {
                  console.log(error.response)
                  console.error('API Error:',error.response.status);
              } 
            };
            // setLoadUnread(false)
        };


        export const sellerChangePassword = (changePassword, setLoadPass, setPassError, setPassSuccess, handleCancelPassword) => async () => {
          setLoadPass(true)
          const loginToken =  localStorage.getItem('token');
          const headers = {
            'Authorization': `Bearer ${loginToken}`,
          };
          try{
            const response = await axios.put(`${BASE_URL}/seller/update-password`, changePassword, { headers });
            if (response.status === 200) {
              setPassSuccess('Password Changed Successfully!')
              setPassError('')
              handleCancelPassword();
              console.log(response.data.messsage)
            } else if (response.status !== 200) {
              console.log('profile upload failed with status code:', response.status);
            } 
          } catch(error) {
              if (error) {
                  // console.log(error.response.data.message)
                  setPassError(error.response.data.message)
                  setPassSuccess('')
                  console.error('API Error:',error.response.status);
              } else if (error.request) {
                console.log(error.response.data.data.message);
                // The request was made but no response was received (e.g., network issue)
                console.error('Network Error:', error.request);
              } 
            };
          
            setLoadPass(false)
        };



                // GET COMMENTS
        export const sellersGetComments = (setChat, setLoadChat, id) => async () => {
          setLoadChat(true)
          const loginToken =  localStorage.getItem('token');
          const headers = {
            'Authorization': `Bearer ${loginToken}`,
          };
          try{
            const response = await axios.get(`${BASE_URL}/admin/broadcast/broadcast-response/comments/${id}`, { headers },);
            if (response.status === 200) {
              // console.log(response.data.data.comments.data, 'SELLERS CHAT')
              setChat(response.data.data.comments.data)
              setLoadChat(false)
            } else if (response.status !== 200) {
              console.log('request failed status code:', response.status);
            } 
          } catch(error) {
              setLoadChat(false)
              if (error.response.data.message) {
                  console.log(error.response)
                  console.error('API Error:',error.response.status);
              } else if (error.request) {
                console.log(error.response.data.data.message);
              //   setError('Please check your internet connection...')
              } 
            };
        };


        // SEND COMMENT
        export const sellerSendComment = (values, setComment, clearInput, setLoading) => async () => {
          setLoading(true)
          clearInput()
          const loginToken =  localStorage.getItem('token');
          const headers = {
            'Authorization': `Bearer ${loginToken}`,
          };
          try{
            const response = await axios.post(`${BASE_URL}/seller/broadcast-response/comments`, values, { headers });
            if (response.status === 200) {
              setComment(response.data.data.comment)
              // console.log('comment sent successfully');
              setLoading(false)
            } else if (response.status !== 200) {
              console.log('Registration failed with status code:', response.status);
            } 
          } catch(error) {
              if (error.response) {
                // The server responded with an error (e.g., HTTP status code 4xx or 5xx)
                console.log(error.response)
                console.error('API Error:', error.response.status);
              } else if (error.request) {
                // The request was made but no response was received (e.g., network issue)
                // setError('Please check your internet connection...')
                console.error('Network Error:', error.request);
              } 
            };
          
            setLoading(false)
        };