import React, { useEffect } from 'react'
import OurMetalsIntro from '../components/ourMetals/OurMetalsIntro'
import Footer from '../components/general/Footer'
import { useLocation } from 'react-router-dom';
import MetaTags from '../MetaTags';

const OurMetals = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  return (
    <div className='flex flex-col items-center justify-start mx-auto max-w-screen-2xl'>
          <MetaTags
          title="Our Metals Page" 
          description="Check the different types of metals we have" 
          link="/our-metals"
           />
         <OurMetalsIntro />
         <Footer />
    </div>
  )
}

export default OurMetals
