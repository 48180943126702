import axios from "axios";




const BASE_URL = process.env.REACT_APP_API_URL

    // PAYMENT RECEIPT UPLOAD
    export const uploadReceipt = (formData, setError, setLoading, setPayment) => async () => {
        setLoading(true)
        const loginToken =  localStorage.getItem('token');
        const headers = {
          'Authorization': `Bearer ${loginToken}`,
        };
        try{
          const response = await axios.post(`${BASE_URL}/buyer/purchase-request/upload-receipt/4`, formData, { headers });
          if (response.status === 200) {
            // console.log(response);
            // console.log('payment receipt uploaded successfully')
            setPayment(false)
          } else if (response.status !== 200) {
            console.log('receipt upload failed with status code:', response.status);
          } 
        } catch(error) {
            if (error.response.data.message) {
                console.log(error.response)
                setError(error.response.data.message)
                console.error('API Error:',error.response.status);
            } else if (error.request) {
              console.log(error.response.data.data.message);
              // The request was made but no response was received (e.g., network issue)
              setError('Please check your internet connection...')
              // console.error('Network Error:', error.request);
            } 
          };
        
          setLoading(false)
      };



    // HOME PAGE METRICS
    export const metricsData = (setMetrics, setLoading) => async () => {
        setLoading(true)
        const loginToken =  localStorage.getItem('token');
        const headers = {
          'Authorization': `Bearer ${loginToken}`,
        };
        try{
          const response = await axios.get(`${BASE_URL}/buyer/purchase-request/metrics`, { headers });
          if (response.status === 200) {
            // console.log(response.data.data)
            setMetrics(response.data.data)
          } else if (response.status !== 200) {
            console.log('request failed status code:', response.status);
          } 
        } catch(error) {
            if (error.response.data.message) {
                console.log(error.response)
                console.error('API Error:',error.response.status);
            } else if (error.request) {
              console.log(error.response.data.data.message);
            //   setError('Please check your internet connection...')
            } 
          };
        
          setLoading(false)
      };


    // PURCHASE REQUESTS DATA
    export const purchaseRequestAction = (setPurchaseRequestsData, setLoading, setTotal, page) => async () => {
        setLoading(true)
        const loginToken =  localStorage.getItem('token');
        const headers = {
          'Authorization': `Bearer ${loginToken}`,
        };
        try{
          const response = await axios.get(`${BASE_URL}/buyer/purchase-request?page=${page}`, { headers });
          if (response.status === 200) {
            setPurchaseRequestsData(response.data.data.purchase_request.data)
            setTotal(response.data.data.purchase_request.total)
            // console.log(response.data.data.purchase_request.data, 'Hello world')
          } else if (response.status !== 200) {
            console.log('request failed status code:', response.status);
          } 
        } catch(error) {
            if (error.response) {
                console.log(error.response)
                console.error('API Error:',error.response.status);
            } else if (error.request) {
              console.log(error.response.data.data.message);
            //   setError('Please check your internet connection...')
            } 
          };
        
          setLoading(false)
      };





    // PURCHASE REQUESTS DATA
    export const BuyerProfileActions = (setUser) => async () => {
        // setLoading(true)
        const loginToken =  localStorage.getItem('token');
        const headers = {
          'Authorization': `Bearer ${loginToken}`,
        };
        try{
          const response = await axios.get(`${BASE_URL}/user`, { headers });
          if (response.status === 200) {
            // console.log(response.data)
            setUser(response.data)
          } else if (response.status !== 200) {
            console.log('request failed status code:', response.status);
          } 
        } catch(error) {
            if (error.response.data.message) {
                console.log(error.response)
                console.error('API Error:',error.response.status);
            } else if (error.request) {
              console.log(error.response.data.data.message);
            //   setError('Please check your internet connection...')
            } 
          };
        
        //   setLoading(false)
      };


    // SEARCH COMMODITY
    export const SearchCommodity = (setSearchResults, searchTerm) => async () => {
      const loginToken =  localStorage.getItem('token');
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${loginToken}`,
        };
        try {
          const response = await axios.get(`${BASE_URL}/buyer/purchase-request?limit=2&page=1&filter=open&sort=asc&search=${searchTerm}`, { headers },{
              params: {
                  limit: 2,
                  page: 1,
                  filter: 'open',
                  sort: 'asc',
                  search: searchTerm,
                }
          });
          setSearchResults(response.data.data.purchase_request.data);
          // console.log(response.data.data.purchase_request.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };


    export const saveProfile = (values, setLoading, setEdit) => async () => {
      setLoading(true)
      const loginToken =  localStorage.getItem('token');
      const headers = {
        'Authorization': `Bearer ${loginToken}`,
      };
      try{
        const response = await axios.put(`${BASE_URL}/buyer`, values, { headers });
        if (response.status === 200) {
          // console.log('profile uploaded successfully')
          setEdit(true)
        } else if (response.status !== 200) {
          console.log('profile upload failed with status code:', response.status);
        } 
      } catch(error) {
          if (error.response.data.message) {
              console.log(error.response)
              console.error('API Error:',error.response.status);
          } else if (error.request) {
            console.log(error.response.data.data.message);
            // The request was made but no response was received (e.g., network issue)
            console.error('Network Error:', error.request);
          } 
        };
      
        setLoading(false)
    };




    // // BROADCAST EVENT LISTS
    // export const getBroadcasts = (setActiveRequest, setLoading, setError) => async () => {
    //   setLoading(true)
    //   const loginToken =  localStorage.getItem('token');
    //   const headers = {
    //     'Authorization': `Bearer ${loginToken}`,
    //   };
    //   try{
    //     const response = await axios.get(`${BASE_URL}/seller/broadcast/`, { headers });
    //     if (response.status === 200) {
    //       setActiveRequest(response.data.data)
    //       // console.log(response.data.data)
    //     } else if (response.status !== 200) {
    //       console.log('request failed status code:', response.status);
    //     } 
    //   } catch(error) {
    //       if (error.response.data.message) {
    //           console.log(error.response)
    //           console.error('API Error:',error.response.status);
    //       } else if (error.request) {
    //         console.log(error.response.data.data.message);
    //         setError('Please check your internet connection...')
    //       } 
    //     };
      
    //     setLoading(false)
    // };




    // // SELLER BROADCASTS
    // export const searchBroadcastList = (setSearchResults, searchTerm) => async () => {
    //   const loginToken =  localStorage.getItem('token');
    //   const headers = {
    //     'Content-Type': 'application/json',
    //     'Authorization': `Bearer ${loginToken}`,
    //     };
    //     try {
    //       const response = await axios.get(`${BASE_URL}/seller/broadcast?start_date=2024-02-01&end_date=2024-03-28&search=${searchTerm}`, { headers },{
    //           params: {
    //               limit: 2,
    //               page: 1,
    //               filter: 'open',
    //               sort: 'asc',
    //               search: searchTerm,
    //               start_date: '2024-02-01',
    //               end_date: '2024-03-28',
    //             }
    //       });
    //       setSearchResults(response.data.data);
    //       // console.log(response.data.data.buyer.data);
    //     } catch (error) {
    //       console.error('Error fetching data:', error);
    //     }
    // };


    // //BROADCAST DETAILS
    // export const broadcastDetails = (setBroadcastDetails, setLoading, setError, item) => async () => {
    //   setLoading(true)
    //   const loginToken =  localStorage.getItem('token');
    //   const headers = {
    //     'Authorization': `Bearer ${loginToken}`,
    //   };
    //   try{
    //     const response = await axios.get(`${BASE_URL}/seller/broadcast/${item}`, { headers });
    //     if (response.status === 200) {
    //       setBroadcastDetails(response.data.data)
    //       // console.log(response.data.data, 'Hello world1')
    //     } else if (response.status !== 200) {
    //       console.log('request failed status code:', response.status);
    //     } 
    //   } catch(error) {
    //       if (error.response.data.message) {
    //           console.log(error.response)
    //           console.error('API Error:',error.response.status);
    //       } else if (error.request) {
    //         console.log(error.response.data.data.message);
    //         setError('Please check your internet connection...')
    //       } 
    //     };
      
    //     setLoading(false)
    // };


            // NOTIFICATIONS
            export const buyersNotifications = (setData, setLoading) => async () => {
              setLoading(true)
              const loginToken =  localStorage.getItem('token');
              const headers = {
                'Authorization': `Bearer ${loginToken}`,
              };
              try{
                const response = await axios.get(`${BASE_URL}/user/notification`, { headers });
                if (response.status === 200) {
                  // console.log(response.data.data.notification.data)
                  setData(response.data.data.notification.data)
                } else if (response.status !== 200) {
                  console.log('request failed status code:', response.status);
                } 
              } catch(error) {
                  if (error.response.data.message) {
                      console.log(error.response)
                      console.error('API Error:',error.response.status);
                  } else if (error.request) {
                    console.log(error.response.data.data.message);
                  //   setError('Please check your internet connection...')
                  } 
                };
                setLoading(false)
            };
    
    
            // UNREAD NOTIFICATIONS
            export const buyersUnreadNot = (setUnread, setLoadUnread) => async () => {
              setLoadUnread(true)
              const loginToken =  localStorage.getItem('token');
              const headers = {
                'Authorization': `Bearer ${loginToken}`,
              };
              try{
                const response = await axios.get(`${BASE_URL}/user/notification/unread-count`, { headers });
                if (response.status === 200) {
                  // console.log(response.data.data)
                  setUnread(response.data.data)
                } else if (response.status !== 200) {
                  console.log('request failed status code:', response.status);
                } 
              } catch(error) {
                  if (error.response.data.message) {
                      console.log(error.response)
                      console.error('API Error:',error.response.status);
                  } else if (error.request) {
                    console.log(error.response.data.data.message);
                  //   setError('Please check your internet connection...')
                  } 
                };
                setLoadUnread(false)
            };
    
    
            // MARK NOTIFICATIONS AS READ
            export const buyersmarkUnreadNot = (item) => async () => {
              // setLoadUnread(true)
              const loginToken =  localStorage.getItem('token');
              const headers = {
                'Authorization': `Bearer ${loginToken}`,
              };
              try{
                const response = await axios.patch(`${BASE_URL}/user/notification/${item}`, { headers });
                if (response.status === 200) {
                  console.log(response.data.data)
                  // setUnread(response.data.data)
                } else if (response.status !== 200) {
                  console.log('request failed status code:', response.status);
                } 
              } catch(error) {
                  if (error.response.data.message) {
                      console.log(error.response)
                      console.error('API Error:',error.response.status);
                  } 
                };
                // setLoadUnread(false)
            };


            export const buyerChangePassword = (changePassword, setLoadPass, setPassError, setPassSuccess, handleCancelPassword) => async () => {
              setLoadPass(true)
              const loginToken =  localStorage.getItem('token');
              const headers = {
                'Authorization': `Bearer ${loginToken}`,
              };
              try{
                const response = await axios.put(`${BASE_URL}/buyer/update-password`, changePassword, { headers });
                if (response.status === 200) {
                  setPassSuccess('Password Changed Successfully!')
                  setPassError('')
                  handleCancelPassword();
                  console.log(response.data.messsage)
                } else if (response.status !== 200) {
                  console.log('profile upload failed with status code:', response.status);
                } 
              } catch(error) {
                  if (error) {
                      // console.log(error.response.data.message)
                      setPassError(error.response.data.message)
                      setPassSuccess('')
                      console.error('API Error:',error.response.status);
                  } else if (error.request) {
                    console.log(error.response.data.data.message);
                    // The request was made but no response was received (e.g., network issue)
                    console.error('Network Error:', error.request);
                  } 
                };
              
                setLoadPass(false)
            };
