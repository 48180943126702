import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { BuyerProfileActions, buyerChangePassword, saveProfile } from '../../../features/buyer/BuyerActions';

const BuyerProfile = () => {
    const [edit, setEdit] = useState(true)
    const [user, setUser] = useState('')
    const [first, setFirst] = useState(user.first_name)
    const [last, setLast] = useState(user.last_name)
    const [gender, setGender] = useState(user.gender)
    const [phone, setPhone] = useState(user.phone)
    const [address, setAddress] = useState(user.address)
    const [loading, setLoading] = useState(false)

    const [current, setCurrent] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [loadPass, setLoadPass] = useState(false)
    const [passError, setPassError] = useState('')
    const [passSuccess, setPassSuccess] = useState('')


    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(BuyerProfileActions(setUser))
        // console.log(user.last_name);
    }, [dispatch])


    const values = {'first_name': first, 'last_name': last, 'gender': gender, 'phone': phone, 'address': address}

    const handleSave = () => {
        dispatch(saveProfile(values, setLoading, setEdit))
        // console.log(values)
    }

  const toggleDisable = () => {
    setEdit(false); // Toggle the state value
  };

  const changePassword = {'current_password': current, 'new_password': newPassword, 'new_password_confirmation': confirmPassword}

  const handleChangePassword = () => {
    dispatch(buyerChangePassword(changePassword, setLoadPass, setPassError, setPassSuccess, handleCancelPassword))
    // console.log(changePassword)
  }

  const handleCancelPassword = () => {
    setCurrent('')
    setNewPassword('')
    setConfirmPassword('')
  }



  return (
    <div className='flex flex-col items-center justify-start w-full px-5 pb-10 md:px-10 mt-28 lg:mt-0 lg:pt-[100px] xl:pt-[115px] xl:px-8 xl:py-8'>

         {/* PROFILE 1 */}
        <div className='flex flex-col items-start justify-start w-full mt-6 md:flex-row'>
            <div className='flex flex-col items-start justify-start md:w-[30%] lg:w-[40%]'>
                 <p className='text-xs text-[#101828] font-medium xl:text-sm'>
                     Personal info
                 </p>
                 <p className='text-[10px] text-[#667085] font-normal pt-1 xl:text-xs'>
                     This will be displayed on your profile.
                 </p>
            </div>

            <div className='flex flex-col items-center justify-start w-full mt-8 md:ml-16 md:mt-6 lg:ml-20'>
                 <div className='flex flex-row items-center justify-between w-full'>
                    <div className='w-[47%]'>
                        <p className='text-[11px] text-[#101828] font-medium xl:text-xs'>
                            First name
                        </p>
                        <input className='text-[10px] text-[#101828] border-[1px] border-[#DDE1E6] rounded-md h-9 w-full pl-3 mt-1 xl:h-11 xl:text-xs'
                        placeholder={user.first_name}
                        value={first}
                        onChange={(e)=>setFirst(e.target.value)}
                        disabled={edit}
                        />
                    </div>
                    <div className='w-[47%]'>
                        <p className='text-[11px] text-[#101828] font-medium xl:text-xs'>
                            Last name
                        </p>
                        <input className='text-[10px] text-[#101828] border-[1px] border-[#DDE1E6] rounded-md h-9 w-full pl-3 mt-1 xl:h-11 xl:text-xs'
                        placeholder={user.last_name}
                        value={last}
                        onChange={(e)=>setLast(e.target.value)}
                        disabled={edit}
                        />
                    </div>
                  </div>

                  <div className='w-full mt-5'>
                        <p className='text-[11px] text-[#101828] font-medium xl:text-xs'>
                            Gender
                        </p>
                        <input className='text-[10px] text-[#101828] border-[1px] border-[#DDE1E6] rounded-md h-9 w-full pl-3 mt-1 xl:h-11 xl:text-xs'
                        placeholder={user.gender}
                        value={gender}
                        onChange={(e)=>setGender(e.target.value)}
                        disabled={edit}
                        />
                  </div>

                  <div className='w-full mt-5'>
                        <p className='text-[11px] text-[#101828] font-medium xl:text-xs'>
                            Phone Number
                        </p>
                        <input className='text-[10px] text-[#101828] border-[1px] border-[#DDE1E6] rounded-md h-9 w-full pl-3 mt-1 xl:h-11 xl:text-xs'
                        placeholder={user.phone}
                        value={phone}
                        onChange={(e)=>setPhone(e.target.value)}
                        disabled={edit}
                        />
                  </div>

                  <div className='w-full mt-5'>
                        <p className='text-[11px] text-[#101828] font-medium xl:text-xs'>
                            Email
                        </p>
                        <input className='text-[10px] text-[#101828] border-[1px] border-[#DDE1E6] rounded-md h-9 w-full pl-3 mt-1 xl:h-11 xl:text-xs'
                        placeholder={user.email}
                        value={user.email}
                        disabled
                        />
                  </div>

                  <div className='w-full mt-5'>
                        <p className='text-[11px] text-[#101828] font-medium xl:text-xs'>
                            Address
                        </p>
                        <input className='text-[10px] text-[#101828] border-[1px] border-[#DDE1E6] rounded-md h-9 w-full pl-3 mt-1 xl:h-11 xl:text-xs'
                        placeholder={user.address}
                        value={address}
                        onChange={(e)=>setAddress(e.target.value)}
                        disabled={edit}
                        />
                  </div>

                  {/* BUTTONS */}
              <div className='flex items-center justify-end w-full mt-8 xl:mt-12'>
                <button onClick={toggleDisable}
                className={`items-center text-center border-[1px] border-[#DDE1E6] h-8 w-16 rounded-md text-[#2196F3] text-[10px] font-semibold xl:w-20 xl:h-9 xl:text-xs ${!edit && 'opacity-30'}`}>
                   Edit
                </button>
                <button onClick={handleSave}
                 disabled={edit}
                 className={`flex items-center justify-center border-[1px] border-[#DDE1E6] h-8 w-16 rounded-md text-[#FFFFFF] bg-[#2196F3] text-[10px] font-semibold 
                          ml-4 xl:w-20 xl:h-9 xl:text-xs ${edit && 'opacity-30'}`}>
                { loading
                  ?<div className="relative flex items-center justify-center w-7 h-7 border-4 border-gray-500 border-solid rounded-full">
                     <div className="absolute w-7 h-7 border-t-4 border-white border-solid rounded-full animate-spin"></div>
                  </div>
                  : 'Save'
                 }
              </button>
              </div>
            </div>
            

        </div>



        {/* LINE */}
        <div className='border-[1px] border-[#DDE1E6] w-full my-10 xl:my-14'></div>


        
        {/* PROFILE 2 */}
        <div className='flex flex-col items-start justify-start w-full md:flex-row'>
            <div className='flex flex-col items-start justify-start md:w-[30%] lg:w-[40%]'>
                 <p className='text-xs text-[#101828] font-medium xl:text-sm'>
                      Login Details
                 </p>
                 <p className='text-[10px] text-[#667085] font-normal pt-1 xl:text-xs'>
                      Modify your Password
                 </p>
            </div>

            <div className='flex flex-col items-center justify-start w-full mt-8 md:ml-16 md:mt-6 lg:ml-20'>
                  <div className='w-full'>
                        <p className='text-[11px] text-[#101828] font-medium xl:text-xs'>
                             Current Password
                        </p>
                        <input className='text-[10px] text-[#101828] border-[1px] border-[#DDE1E6] rounded-md h-9 w-full pl-3 mt-1 xl:h-11 xl:text-xs'
                        placeholder='Your current password'
                        value={current}
                        onChange={(e)=>setCurrent(e.target.value)}
                        />
                  </div>

                  <div className='w-full mt-5'>
                        <p className='text-[11px] text-[#101828] font-medium xl:text-xs'>
                             New Password                        
                        </p>
                        <input className='text-[10px] text-[#101828] border-[1px] border-[#DDE1E6] rounded-md h-9 w-full pl-3 mt-1 xl:h-11 xl:text-xs'
                        placeholder='Your new password'
                        value={newPassword}
                        onChange={(e)=>setNewPassword(e.target.value)}
                        />
                  </div>

                  <div className='w-full mt-5'>
                        <p className='text-[11px] text-[#101828] font-medium xl:text-xs'>
                             Confirm New Password
                        </p>
                        <input className='text-[10px] text-[#101828] border-[1px] border-[#DDE1E6] rounded-md h-9 w-full pl-3 mt-1 xl:h-11 xl:text-xs'
                        placeholder='Confirm password'
                        value={confirmPassword}
                        onChange={(e)=>setConfirmPassword(e.target.value)}
                        />
                  </div>

            </div>

        </div>

        <div className='flex items-center justify-end w-full mt-6 xl:mt-11'>
             {passError && <p className='text-[10px] font-medium text-red-500 xl:text-xs'>{passError}</p>}
             {passSuccess && <p className='text-[10px] font-medium text-green-500 xl:text-xs'>{passSuccess}</p>}
             
        </div>

        {/* BUTTONS */}
        <div className='flex items-center justify-end w-full mt-3 xl:mt-4'>
             <button onClick={handleCancelPassword}
             className='items-center text-center border-[1px] border-[#DDE1E6] h-8 w-16 rounded-md text-[#2196F3] text-[10px] font-semibold xl:w-20 xl:h-9 xl:text-xs'>
                 Cancel
             </button>
             <button onClick={handleChangePassword}
             className={`flex items-center justify-center border-[1px] border-[#DDE1E6] h-8 w-16 rounded-md text-[#FFFFFF] bg-[#2196F3] text-[10px] font-semibold ml-4 xl:w-20 
             xl:h-9 xl:text-xs ${loadPass && 'opacity-30'}`}>
              { loadPass
                ?<div className="relative flex items-center justify-center w-7 h-7 border-4 border-gray-500 border-solid rounded-full">
                   <div className="absolute w-7 h-7 border-t-4 border-white border-solid rounded-full animate-spin"></div>
                </div>
                : 'Save'
               }
             </button>
        </div>

    </div>
  )
}

export default BuyerProfile
