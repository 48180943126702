import React, { useEffect } from 'react'
import SupportIntro from '../components/support/SupportIntro'
import Faq from '../components/general/Faq'
import Footer from '../components/general/Footer'
import SupportSection3 from '../components/support/SupportSection3'
import { useLocation } from 'react-router-dom'
import MetaTags from '../MetaTags'

const Support = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className='flex flex-col items-center justify-start mx-auto max-w-screen-2xl'>
        <MetaTags
        title="Support Page" 
        description="Find answers to your unanswered questions" 
        link="/support"
         />
        <SupportIntro />
        <Faq />
        <SupportSection3 />
        <Footer />
    </div>
  )
}

export default Support
